<template>
  <div class="bg-scroll">
    <ReviewCard :review="review" :type="modalType" :show-actions="false" bg-color="bg-transparent" />
    <div class="relative">
      <h3 class="mb-2 font-medium">{{ $t('reviews.write_comment') }}</h3>
      <form class="mt-2" name="review" @submit.prevent>
        <div>
          <div class="flex w-full justify-end">
            <div
              v-if="errorMessage"
              class="mb-5 flex w-full items-center justify-between border-l-2 border-danger bg-red-100 p-4 text-sm text-danger"
              @click="errorMessage = null"
            >
              <span class="mr-2 text-red-dark">{{ errorMessage }}</span>
              <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer"></hero-icon>
            </div>
            <div
              v-if="displaySuccess"
              class="mb-5 flex w-full items-center justify-between border-l-2 border-green bg-green-100 p-4 text-sm text-success"
              @click="displaySuccess = null"
            >
              <span class="mr-2 text-green-dark">{{ $t('response_confirmation_modal.success_sent_comment_message') }}</span>
              <hero-icon name="CloseHeroIcon" classes="text-sm hover:cursor-pointer"></hero-icon>
            </div>
          </div>
          <div class="mb-3 flex flex-row items-center justify-end">
            <button
              data-tooltip-target="tooltip-erase-review-response-button"
              class="mr-1 h-8 rounded-full bg-blue px-3 py-1 text-xs text-white hover:bg-blue-dark disabled:cursor-not-allowed disabled:bg-blue-lighter"
              :disabled="loading"
              @click="eraseReviewComment"
            >
              <hero-icon name="TrashHeroIcon" size="small" classes="flex items-center"></hero-icon>
            </button>
            <DefaultTooltip id="tooltip-erase-review-response-button" :message="$t('reviews.bad.tooltip_erase')" />
          </div>
        </div>
        <div class="relative mb-6">
          <div v-show="loading" id="spinner_icon" class="absolute flex h-full w-full items-center justify-center">
            <TurningLoader :message="$t('loading')" text-color="text-textLight" />
          </div>
          <textarea
            id="message"
            v-model="reviewComment"
            rows="4"
            :disabled="loading"
            :autofocus="true"
            name="message"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            :placeholder="$t('reviews.comment_placeholder')"
            :class="loading ? 'opacity-20' : ''"
          >
          </textarea>
        </div>
        <div class="mt-10 flex items-center justify-end">
          <button
            class="mr-2 rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:text-primary"
            @click="closeModal"
          >
            {{ $t('buttons.cancel') }}
          </button>
          <button
            class="flex items-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
            :disabled="disableSubmitButton"
            @click="handleSubmit"
          >
            <div class="mr-2">{{ $t('reviews.bad.send_reply') }}</div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { updateReviewComment, updateSurveyReviewComment } from '@services/reviewService';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['close']);

const props = defineProps({
  // @example:
  // { id:, author:, comment:, avatarURl:, totalRatingNb:, rating:, reviewDisplayDate:, replyDisplayDate:, reply:}
  review: {
    type: Object,
    required: true,
  },
  modalType: {
    type: String, // 'google' or 'review' or 'surveyReview'
    default: 'review',
  },
});

const reviewComment = ref('');
const errorMessage = ref('');
const displaySuccess = ref(false);
const loading = ref(false);

onMounted(() => {
  reviewComment.value = props.review.comment;
});

const disableSubmitButton = computed(() => {
  return reviewComment.value === '' || reviewComment.value === props.review.comment || loading.value;
});

const eraseReviewComment = () => {
  reviewComment.value = '';
};

const closeModal = () => {
  emit('close');
};

const handleSubmit = async () => {
  try {
    loading.value = true;
    switch (props.modalType) {
      case 'review':
        await updateReviewComment(props.review.id, { comment: reviewComment.value });
        break;
      case 'surveyReview':
        await updateSurveyReviewComment(props.review.id, { comment: reviewComment.value });
        break;
    }
    displaySuccess.value = true;
    setTimeout(function () {
      displaySuccess.value = false;
      loading.value = false;
      window.location.reload();
    }, 1100);
  } catch (error) {
    errorMessage.value = (error.response && error.response.data.errors.join('.\n')) || error.message || t('errors.default_message');
    setTimeout(function () {
      errorMessage.value = null;
      loading.value = false;
    }, 1500);
  }
};
</script>
