<template>
  <aside
    id="sidebar"
    class="fixed left-0 top-0 z-40 h-screen -translate-x-full bg-white pt-16 drop-shadow-md transition-transform xl:translate-x-0"
    aria-label="Sidebar"
  >
    <div class="h-full w-72 overflow-y-auto bg-white pb-4 pt-10 dark:bg-gray-800">
      <ul data-accordion="collapse" class="flex h-full flex-col font-medium" data-active-classes="bg-gray-light border-gray">
        <li v-for="(link, index) in topLinks" :key="'upLink' + index">
          <a
            v-if="link.type === 'link'"
            :href="link.href"
            class="group mb-1 flex h-12 items-center pl-10 pr-4 hover:border-r-4 hover:border-primary hover:bg-gray-light hover:text-primary hover:no-underline dark:text-white dark:hover:bg-gray-700"
            :class="link.active === true ? 'border-r-4 border-primary fill-primary text-primary' : 'border-0 fill-secondary text-textSecondary'"
          >
            <div class="mr-6 flex w-5 items-center justify-center rounded-lg">
              <hero-icon :name="link.icon" size="small"></hero-icon>
            </div>
            <h3 class="group-hover:text-primary" :class="link.active === true ? 'text-primary' : 'text-textSecondary'">
              {{ link.title }}
            </h3>
          </a>
          <div v-else class="mb-1">
            <button
              :id="'accordion-collapse-heading-' + index"
              type="button"
              class="group flex h-12 w-full items-center justify-between border-r-4 border-transparent pl-10 pr-4 text-textSecondary transition duration-75 hover:border-primary hover:bg-gray-light hover:text-primary dark:text-white dark:hover:bg-gray-700"
              :data-accordion-target="'#accordion-collapse-body-' + index"
              :aria-expanded="accordionActive(link)"
              aria-controls="accordion-collapse-body-1"
            >
              <div class="flex items-center justify-start">
                <div class="mr-6 flex w-5 items-center justify-center rounded-lg bg-transparent">
                  <hero-icon :name="link.icon" size="default"></hero-icon>
                </div>
                <h3 class="text-textSecondary group-hover:text-primary">{{ link.title }}</h3>
              </div>
              <hero-icon data-accordion-icon classes="rotate-180" name="ChevronUpHeroIcon" size="small" aria-hidden="true"></hero-icon>
            </button>
            <ul :id="'accordion-collapse-body-' + index" class="hidden space-y-1 pb-4 pt-1" :aria-labelledby="'accordion-collapse-heading-' + index">
              <li v-for="(sublink, subindex) in link.sublinks" :key="'sublink' + subindex">
                <a
                  :href="sublink.href"
                  class="group flex h-10 w-full items-center border-r-4 pl-24 pr-4 transition duration-75 hover:border-r-4 hover:border-primary hover:bg-gray-light hover:text-primary hover:no-underline dark:text-white dark:hover:bg-gray-700"
                  :class="sublink.active === true ? 'border-primary' : 'border-transparent'"
                >
                  <h3 class="group-hover:text-primary" :class="sublink.active === true ? 'text-primary' : 'text-textSecondary'">
                    {{ sublink.title }}
                  </h3>
                  <div v-if="sublink.icon" class="ml-2 h-5 w-5">
                    <hero-icon :name="sublink.icon" class="h-5 w-5 text-yellow"></hero-icon>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <div class="grow"></div>
        <hr />
        <div class="mb-2"></div>
        <li v-for="(link, index) in bottomLinks" :key="'bottomLink' + index">
          <a
            v-if="link.type === 'link'"
            :href="link.href"
            class="group mb-1 flex h-12 items-center pl-10 pr-4 hover:border-r-4 hover:border-primary hover:bg-gray-light hover:text-primary hover:no-underline dark:text-white dark:hover:bg-gray-700"
            :class="link.active === true ? 'border-r-4 border-primary text-primary' : 'border-0 text-textSecondary'"
          >
            <div class="mr-6 flex w-5 items-center justify-center rounded-lg">
              <hero-icon :name="link.icon" size="default"></hero-icon>
            </div>
            <h3 class="group-hover:text-primary" :class="link.active === true ? 'text-primary' : 'text-textSecondary'">
              {{ link.title }}
            </h3>
          </a>
          <div v-else class="mb-1">
            <button
              :id="'accordion-collapse-heading-' + index"
              type="button"
              class="group flex h-12 w-full items-center justify-between border-r-4 border-transparent pl-10 pr-4 text-textSecondary transition duration-75 hover:border-primary hover:bg-gray-light hover:text-primary dark:text-white dark:hover:bg-gray-700"
              :data-accordion-target="'#accordion-collapse-body-' + index"
              :aria-expanded="accordionActive(link)"
              aria-controls="accordion-collapse-body-1"
            >
              <div class="flex items-center justify-start">
                <div class="mr-6 flex w-5 items-center justify-center rounded-lg bg-transparent">
                  <hero-icon :name="link.icon" size="default"></hero-icon>
                </div>
                <h3 class="text-textSecondary group-hover:text-primary">{{ link.title }}</h3>
              </div>
              <hero-icon data-accordion-icon classes="rotate-180" name="ChevronUpHeroIcon" size="small" aria-hidden="true"></hero-icon>
            </button>
            <ul :id="'accordion-collapse-body-' + index" class="hidden space-y-1 pb-4 pt-1" :aria-labelledby="'accordion-collapse-heading-' + index">
              <li v-for="(sublink, subindex) in link.sublinks" :key="'sublink' + subindex">
                <a
                  :href="sublink.href"
                  class="group flex h-10 w-full items-center border-r-4 pl-24 pr-4 transition duration-75 hover:border-r-4 hover:border-primary hover:bg-gray-light hover:text-primary hover:no-underline dark:text-white dark:hover:bg-gray-700"
                  :class="sublink.active === true ? 'border-primary' : 'border-transparent'"
                >
                  <h3 class="group-hover:text-primary" :class="sublink.active === true ? 'text-primary' : 'text-textSecondary'">
                    {{ sublink.title }}
                  </h3>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </aside>
</template>
<script setup>
defineProps({
  topLinks: {
    type: Array,
    required: true,
  },
  bottomLinks: {
    type: Array,
    required: true,
  },
});

const accordionActive = parentLink => {
  let active = false;
  parentLink.sublinks.every(sublink => {
    if (sublink.active === true) {
      active = true;
      return false;
    }
    return true;
  });
  return active;
};
</script>
<style scoped></style>
