<template>
  <div class="flex flex-col">
    <div
      v-if="errorMessage"
      class="mb-5 flex items-center justify-between border-l-2 border-danger bg-red-100 p-4 text-sm text-danger"
      @click="errorMessage = null"
    >
      <span class="mr-2 text-red-dark">{{ errorMessage }}</span>
      <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer"></hero-icon>
    </div>
    <div
      v-if="displaySuccess"
      class="mb-5 flex items-center justify-between border-l-2 border-green bg-green-100 p-4 text-sm text-success"
      @click="displaySuccess = null"
    >
      <span class="mr-2 text-green-dark">{{ $t('response_confirmation_modal.success_sent_response_message') }}</span>
      <hero-icon name="CloseHeroIcon" classes="text-sm hover:cursor-pointer"></hero-icon>
    </div>
    <form class="mt-2 text-center" :name="formName" @submit.prevent>
      <h3 class="mb-4 font-medium">{{ $t('response_confirmation_modal.text') }}</h3>
      <div class="mt-10 flex items-center justify-end">
        <button
          class="rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:text-primary"
          @click="closeModal"
        >
          {{ $t('buttons.cancel') }}
        </button>
        <button
          class="relative flex items-center rounded-lg bg-primary px-6 py-2 text-center text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:w-24 disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
          :disabled="loading"
          @click="handleSubmit"
        >
          <div v-show="!loading">{{ $t('response_confirmation_modal.send') }}</div>
          <div v-show="loading" class="flex w-full justify-center">
            <TurningLoader message="" size="sm" text-color="text-textLight" />
          </div>
        </button>
      </div>
    </form>
  </div>
</template>
<script setup>
import { submitReviewResponse, submitGoogleReviewResponse, submitSurveyReviewResponse } from '@services/reviewService';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['close']);

const props = defineProps({
  clinicId: {
    type: Number,
    required: true,
  },
  reviewId: {
    type: Number,
    required: true,
  },
  reviewResponse: {
    type: String,
    required: true,
  },
  responseTemplateName: {
    type: String,
    required: true,
  },
  type: {
    type: String, // 'google' or 'review' or 'surveyReview'
    default: 'review',
  },
});
const errorMessage = ref(null);
const displaySuccess = ref(false);
const loading = ref(false);

const formName = computed(() => {
  let name = 'review';
  switch (props.type) {
    case 'google':
      name = 'google_review';
      break;
    case 'review':
      name = 'review';
      break;
    case 'surveyReview':
      name = 'survey_review';
      break;
  }
  return name;
});

const closeModal = () => {
  emit('close');
};

const handleSubmit = async () => {
  try {
    switch (props.type) {
      case 'google':
        await submitGoogleReviewResponse({ reviewResponse: props.reviewResponse, reviewId: props.reviewId, clinicId: props.clinicId });
        break;
      case 'review':
        await submitReviewResponse({
          reviewResponse: props.reviewResponse,
          reviewId: props.reviewId,
          responseTemplateName: props.responseTemplateName,
        });
        break;
      case 'surveyReview':
        await submitSurveyReviewResponse({ reviewResponse: props.reviewResponse, reviewId: props.reviewId });
        break;
    }
    displaySuccess.value = true;
    loading.value = true;
    setTimeout(function () {
      displaySuccess.value = false;
      loading.value = false;
      window.location.reload();
    }, 1500);
  } catch (error) {
    errorMessage.value = (error.response && error.response.data.errors.join('.\n')) || error.message || t('errors.default_message');
    setTimeout(function () {
      error.value = null;
    }, 2000);
  }
};
</script>
