<template>
  <apexchart :width="width" :height="height" :options="chartOptions" :series="series"></apexchart>
</template>

<script setup>
import { computed } from 'vue';
import _ from 'lodash';
import fr from 'apexcharts/dist/locales/fr.json';

const props = defineProps({
  height: {
    type: String,
    default: '100%',
  },
  width: {
    type: String,
    default: '100%',
  },
  options: {
    type: Object,
    required: true,
  },
  tooltipYName: {
    type: String,
    required: false,
    default: '',
  },
  enableTools: {
    type: Boolean,
    required: false,
    default: false,
  },
  enableDatalabels: {
    type: Boolean,
    required: false,
    default: false,
  },
  series: {
    type: Array,
    default: () => {
      return [{ name: 'Data', data: [22, 0, 0, 0, 3], color: '#4691F7' }];
    },
  },
});

const chartOptions = computed(() => {
  let merged_options = _.merge(defaultOptions(), props.options);
  return merged_options;
});

const sumOfCounts = computed(() => {
  return props.series.reduce((sum, dataPoint) => {
    return sum + dataPoint.data.reduce((pointSum, value) => pointSum + value, 0);
  }, 0);
});

const defaultOptions = () => {
  return {
    chart: {
      type: 'bar',
      locales: [fr],
      defaultLocale: 'fr',
      toolbar: {
        show: props.enableTools,
        offsetY: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 5,
        borderRadiusApplication: 'end',
        barHeight: '80%',
        dataLabels: {
          position: 'bottom',
          total: {
            enabled: true,
          },
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
      },
      followCursor: true,
      y: {
        formatter: undefined,
        title: {
          formatter: seriesName => {
            return props.tooltipYName ? props.tooltipYName : seriesName;
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      formatter: function (val) {
        // return val == 0 ? '' : val + ' ' + props.tooltipYName + ' ' + (val * 100 / sumOfCounts.value).toFixed(0) + '%';
        return val == 0 ? '' : ((val * 100) / sumOfCounts.value).toFixed(0) + '%';
      },
      style: {
        colors: ['#9a7009'],
        fontSize: '13px',
        fontWeight: 900,
      },
      offsetX: 0,
    },
    yaxis: {
      type: 'numeric',
      labels: {
        style: {
          fontSize: '16px',
          fontWeight: 400,
        },
      },
    },
    xaxis: {
      type: 'categories',
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 600,
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },
    fill: {
      opacity: 1,
    },
  };
};
</script>
<style scoped></style>
