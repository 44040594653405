<template>
  <component :is="selectedIcon" :class="mergeTailwindClasses" :svg-class="mergeTailwindClasses"></component>
</template>

<script setup>
import { computed } from 'vue';
import * as Icons from '@lib/icons.js';

const props = defineProps({
  name: {
    type: String, // example: check-circle => https://heroicons.com/
    required: true,
  },
  classes: {
    type: String,
    default: '',
    required: false,
  },
  size: {
    type: String,
    default: '',
    required: false,
  },
});

const selectedIcon = computed(() => {
  return Icons[props.name];
});

const sizeClasses = computed(() => {
  let sizeClasses = '';
  if (props.size !== '') {
    switch (props.size) {
      case 'default':
        sizeClasses = 'w-5 h-5';
        break;
      case 'large':
        sizeClasses = 'w-6 h-6';
        break;
      case 'xlarge':
        sizeClasses = 'w-8 h-8';
        break;
      case 'small':
        sizeClasses = 'w-4 h-4';
        break;
      case 'xmall':
        sizeClasses = 'w-3 h-3';
        break;
      default:
        break;
    }
  }
  return sizeClasses;
});

const mergeTailwindClasses = computed(() => {
  // Split both class strings into arrays of classes
  const classes1 = sizeClasses.value.split(' ');
  const classes2 = props.classes.split(' ');
  // Merge the arrays and remove duplicates
  const mergedClasses = [...new Set([...classes1, ...classes2])];
  // Join the merged classes back into a single string
  const mergedClassString = mergedClasses.join(' ');
  return mergedClassString;
});
</script>
