<template>
  <div class="flex w-full flex-col items-center justify-center py-4">
    <div class="flex w-full items-start justify-between">
      <div class="h-5 w-5"></div>
      <MultiStepper :steps="steps" :current-step="currentStep" />
      <div>
        <button
          class="flex h-8 w-8 items-center justify-center rounded-lg bg-blue-300 hover:bg-blue hover:drop-shadow-sm"
          data-tooltip-target="tooltip-back"
          data-tooltip-placement="bottom"
          @click="handleCancel"
        >
          <hero-icon name="CloseHeroIcon" size="default" classes="text-white"></hero-icon>
        </button>
        <default-tooltip id="tooltip-back" classes="bg-blue-lighter text-blue-500" :message="$t('buttons.cancel')" />
      </div>
    </div>
    <div
      class="mt-10 min-h-[485px] w-11/12 overflow-auto rounded-xl bg-white p-10 drop-shadow-lg sm:p-12 md:w-3/4 md:px-16 md:py-14 lg:w-[800px] lg:px-24 lg:py-16"
    >
      <EstablishmentSelectionForm
        v-show="pages[currentStep] === 'establishments_selection'"
        :clinics="clinics"
        :current-clinic="currentClinic"
        :before-path="beforePath"
        @next-step="goNextStep"
      />
      <SollicitationConfigurationForm
        v-show="pages[currentStep] === 'configuration'"
        :clinics="clinics"
        :current-clinic="currentClinic"
        :esatis-activated="esatisActivated"
        :esatis-only-for-satisfied-patient-message="esatisOnlyForSatisfiedPatientMessage"
        :before-path="beforePath"
        :sollicitation-type="type"
        :surveys="surveys"
        :show-sending-option="showSendingOption"
        :enable-sms-option="enableSmsOption"
        @next-step="goNextStep"
        @previous-step="goPreviousStep"
      />
      <NewPatientForm
        v-show="pages[currentStep] === 'form'"
        :civilities="civilities"
        :clinics="clinics"
        :current-clinic="currentClinic"
        :before-path="beforePath"
        :sollicitation-type="type"
        :tags="tags"
        @previous-step="goPreviousStep"
      />
      <CsvUploadForm
        v-show="pages[currentStep] === 'upload'"
        :clinics="clinics"
        :current-clinic="currentClinic"
        :before-path="beforePath"
        :sollicitation-type="type"
        :tags="tags"
        :default-no-of-days-for-re-import="defaultNoOfDaysForReImport"
        @previous-step="goPreviousStep"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import EstablishmentSelectionForm from './forms/EstablishmentSelectionForm.vue';
import SollicitationConfigurationForm from './forms/SollicitationConfigurationForm.vue';
import NewPatientForm from './forms/NewPatientForm.vue';
import CsvUploadForm from './forms/CsvUploadForm.vue';
import { useImportPatientsStore } from '@stores/importPatients.js';
const store = useImportPatientsStore();

const props = defineProps({
  type: {
    type: String, // manual, file_one_establishment, file_multiple_establishments
    required: true,
  },
  clinics: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
  currentClinic: {
    type: Object,
    required: false,
    default: () => {
      return {};
    },
  },
  tags: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
  surveys: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
  civilities: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
  beforePath: {
    type: String,
    required: true,
  },
  esatisActivated: {
    type: Boolean,
    required: false,
    default: false,
  },
  esatisOnlyForSatisfiedPatientMessage: {
    type: Boolean,
    required: false,
    default: false,
  },
  showSendingOption: {
    type: Boolean,
    required: false,
    default: true,
  },
  enableSmsOption: {
    type: Boolean,
    required: false,
    default: false,
  },
  defaultNoOfDaysForReImport: {
    type: Number,
    required: true,
  },
});

const currentStep = ref(0);
const steps = ref([]);
const pages = ref([]);

onMounted(() => {
  store.updateImportType({
    importType: props.type,
  });
  switch (props.type) {
    case 'manual':
      steps.value = [
        { name: 'Configuration', icon: 'SettingHeroIcon' },
        { name: 'Informations', icon: 'DocumentOutlineIonIcon' },
      ];
      pages.value = ['configuration', 'form'];
      break;
    case 'file_one_establishment':
      store.updateEstablishments({
        establishments: props.clinics.map(c => c.id),
      });
      steps.value = [
        { name: 'Configuration', icon: 'SettingHeroIcon' },
        { name: 'Chargement', icon: 'DocumentOutlineIonIcon' },
      ];
      pages.value = ['configuration', 'upload'];
      break;
    case 'file_multiple_establishments':
      steps.value = [
        { name: 'Etablissements', icon: 'BusinessOutlineIonIcon' },
        { name: 'Configuration', icon: 'SettingHeroIcon' },
        { name: 'Chargement', icon: 'DocumentOutlineIonIcon' },
      ];
      pages.value = ['establishments_selection', 'configuration', 'upload'];
      break;
  }
});

const goNextStep = () => {
  scrollToTop();
  currentStep.value = currentStep.value + 1;
};

const goPreviousStep = () => {
  scrollToTop();
  currentStep.value = currentStep.value - 1;
};

const scrollToTop = () => {
  // Scroll to the top of the page
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // for smooth scrolling, if supported
  });
};

const handleCancel = () => {
  store.$reset();
  window.location.href = props.beforePath;
};
</script>
