<template>
  <div>
    <button class="group flex items-center justify-start" :class="buttonClasses" @click="handleDownloadSurveyReviews">
      <div v-show="displayActionIcon" class="flex h-full items-center justify-center">
        <TurningLoader v-if="loading" size="xs" class="mr-2" />
        <hero-icon v-else :name="iconName" classes="mr-2" size="small"></hero-icon>
      </div>
      {{ $t('surveys.card.download_reviews') }}
    </button>
    <Modal
      v-model="showDownloadConfirmationModal"
      :title="$t('surveys.index.confirmation_modal.title')"
      wrapper-class="modal-wrapper"
      modal-class="modal modal-md"
    >
      <div class="m-3 text-center">
        <hero-icon v-if="confirmationModalIsSuccess" name="CheckCircleHeroIcon" classes="w-[60px] text-green"></hero-icon>
        <hero-icon v-else name="WarningHeroIcon" classes="w-[60px] text-red-500"></hero-icon>
        <p>{{ confirmationMessage }}</p>
      </div>
    </Modal>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import axios from 'axios';

const props = defineProps({
  downloadSurveyReviewsPath: {
    type: String,
    required: true,
  },
  buttonClasses: {
    type: String,
    required: false,
    default: () => {
      return 'font-medium border-none text-sm bg-transparent text-primary hover:text-blue-dark hover:drop-shadow-sm';
    },
  },
  iconName: {
    type: String,
    required: false,
    default: 'cloud-download',
  },
  displayActionIcon: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const showDownloadConfirmationModal = ref(false);
const loading = ref(false);
const confirmationMessage = ref('');
const confirmationModalIsSuccess = ref(true);

const handleDownloadSurveyReviews = async () => {
  loading.value = true;
  const url = props.downloadSurveyReviewsPath;
  const response = await axios.get(url, { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } });
  loading.value = false;
  const responseData = response.data;
  if (responseData.success === true) {
    confirmationModalIsSuccess.value = true;
    confirmationMessage.value = responseData.message;
    showDownloadConfirmationModal.value = true;
  } else {
    confirmationModalIsSuccess.value = false;
    confirmationMessage.value = responseData.message;
    showDownloadConfirmationModal.value = true;
  }
};
</script>
