<template>
  <div class="flex flex-col">
    <div class="flex w-full justify-end">
      <div
        v-if="errorMessage"
        class="mb-5 flex w-full items-center justify-between border-l-2 border-danger bg-red-100 p-4 text-sm text-danger"
        @click="errorMessage = null"
      >
        <span class="mr-2 text-red-dark">{{ errorMessage }}</span>
        <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer"></hero-icon>
      </div>
      <div
        v-if="displaySuccess"
        class="mb-5 flex w-full items-center justify-between border-l-2 border-green bg-green-100 p-4 text-sm text-success"
        @click="displaySuccess = null"
      >
        <span class="mr-2 text-green-dark">{{ displaySuccess }}</span>
        <hero-icon name="CloseHeroIcon" classes="text-sm hover:cursor-pointer"></hero-icon>
      </div>
      <div v-if="displayEditHint" class="mb-5 flex w-full items-center justify-between border-l-2 border-info bg-blue-100 p-4 text-sm text-primary">
        <span class="text-dark mr-2">{{ $t('reviews.edit_template.hint_message') }}</span>
        <hero-icon name="InformationIonIcon" size="large" classes="pt-1 mr-2 text-blue-light"></hero-icon>
      </div>
    </div>
    <form class="mt-2 text-left" :name="type" @submit.prevent>
      <h3 class="mb-4 font-medium">{{ creatingTemplate ? $t('reviews.bad.save_reply_as_template') : $t('reviews.bad.edit_reply_as_template') }}</h3>
      <div v-if="creatingTemplate" class="mb-6 rounded-lg bg-blue-100 p-5">
        <LinebreakHTMLWrapper :text="reviewResponse" />
      </div>
      <textarea
        v-else
        id="templateResponse"
        v-model="templateResponse"
        rows="4"
        :disabled="loading"
        :autofocus="true"
        name="templateResponse"
        class="mb-4 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        :class="loading ? 'opacity-20' : ''"
      >
      </textarea>
      <label class="mb-4 block font-medium tracking-wide text-gray-700" for="message">
        {{ creatingTemplate ? $t('reviews.save_template.choose_template_to_save') : $t('reviews.edit_template.choose_template_to_edit') }}</label
      >
      <div class="flex items-center justify-start space-x-3">
        <input
          v-model="templateName"
          class="form-input mb-2 mr-2 inline-flex h-10 w-80 shrink-0 overflow-hidden rounded-lg border-gray-300 bg-white px-4 text-xs font-medium hover:drop-shadow-md"
        />
      </div>
      <div class="mt-10 flex items-center justify-end">
        <button
          v-if="props.template"
          class="rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-red hover:text-red-dark"
          @click="showDeleteReviewResponseTemplateModal = true"
        >
          {{ $t('buttons.delete') }}
        </button>
        <button
          class="rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:text-primary"
          @click="closeModal"
        >
          {{ $t('buttons.cancel') }}
        </button>
        <button
          class="flex items-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
          :disabled="disableSubmitButton"
          @click="submitTemplate"
        >
          {{ $t('automatic_google_review_response.save_button') }}
        </button>
      </div>
    </form>
  </div>
  <Modal
    v-model="showDeleteReviewResponseTemplateModal"
    :title="$t('automatic_google_review_response.delete_modal.title')"
    wrapper-class="modal-wrapper"
    modal-class="modal modal-md"
  >
    <ConfirmationModal
      :message="$t('automatic_google_review_response.delete_modal.message')"
      :loading="loading"
      @confirmed="handleDeleteTemplate"
      @close="showDeleteReviewResponseTemplateModal = false"
    />
  </Modal>
</template>
<script setup>
import { ref, computed } from 'vue';
import {
  createReviewResponseTemplate,
  updateReviewResponseTemplate,
  updateGoogleReviewResponseTemplate,
  createGoogleReviewResponseTemplate,
  deleteGoogleReviewResponseTemplate,
  deleteReviewResponseTemplate,
} from '@services/reviewService';
import LinebreakHTMLWrapper from '@components/shared/wrapper/LinebreakHTMLWrapper';
import ConfirmationModal from '@components/shared/modals/ConfirmationModal.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['close']);

const props = defineProps({
  templatesSize: {
    type: Number,
    required: true,
  },
  reviewResponse: {
    type: String,
    required: true,
  },
  // passed template determine if we are editing or creating a template
  template: {
    type: String,
    default: '',
  },
  clinicId: {
    type: Number,
    required: true,
  },
  type: {
    type: String,
    default: 'review', // or 'google'
  },
});

const errorMessage = ref(null);
const displaySuccess = ref(null);
const loading = ref(false);
const selectedTemplate = ref(props.template);
const templateName = ref(props.template.name);
const templateResponse = ref(props.reviewResponse);
const showDeleteReviewResponseTemplateModal = ref(false);
const orderToRegister = ref(props.templatesSize + 1);

const disableSubmitButton = computed(() => {
  if (creatingTemplate.value) {
    return disableSubmitCreateTemplate.value || loading.value === true;
  } else {
    return disableSubmitEditTemplate.value || loading.value === true;
  }
});

const displayEditHint = computed(() => {
  return disableSubmitEditTemplate.value;
});

const creatingTemplate = computed(() => {
  return selectedTemplate.value === '';
});

const sameResponseOnSelectedTemplate = computed(() => {
  return templateResponse.value === props.reviewResponse.value;
});

const sameNameOnSelectedTemplate = computed(() => {
  return templateName.value === selectedTemplate.value.name;
});

const disableSubmitEditTemplate = computed(() => {
  return sameNameOnSelectedTemplate.value && sameResponseOnSelectedTemplate.value;
});

const disableSubmitCreateTemplate = computed(() => {
  return props.reviewResponse.trim() === '' || templateName.value === undefined || templateName.value.trim() == '';
});

const closeModal = () => {
  emit('close');
};

const handleDeleteTemplate = async () => {
  try {
    loading.value = true;
    if (props.type === 'google') {
      await deleteGoogleReviewResponseTemplate({ googleReviewResponseTemplateId: selectedTemplate.value.id });
      setTimeout(function () {
        loading.value = false;
        window.location.reload(); // we need to reload page as templates have to be updated on the page
      }, 1500);
    } else {
      await deleteReviewResponseTemplate({ reviewResponseTemplateId: selectedTemplate.value.id });
      setTimeout(function () {
        loading.value = false;
        window.location.reload(); // we need to reload page as templates have to be updated on the page
      }, 1500);
    }
  } catch (error) {
    errorMessage.value = error.message;
    setTimeout(function () {
      errorMessage.value = null;
    }, 1500);
  }
};

const submitTemplate = async () => {
  try {
    if (props.type === 'google') {
      // for creating google_rewiew_response_template
      loading.value = true;
      if (creatingTemplate.value) {
        await createGoogleReviewResponseTemplate({
          clinicId: props.clinicId,
          reviewResponse: templateResponse.value,
          templateName: templateName.value,
          templateOrder: orderToRegister.value,
          mode: 'manual',
        });
        displaySuccess.value = t('reviews.save_template.create_success_message');
      } else {
        await updateGoogleReviewResponseTemplate({
          googleReviewResponse: templateResponse.value,
          googleReviewResponseTemplateId: selectedTemplate.value.id,
          googleReviewResponseTemplateName: templateName.value,
        });
        displaySuccess.value = t('reviews.save_template.default_success_message');
      }
      setTimeout(function () {
        displaySuccess.value = null;
        loading.value = false;
        window.location.reload(); // we need to reload page as templates have to be updated on the page
      }, 1500);
    } else {
      // for creating rewiew response template
      loading.value = true;
      if (creatingTemplate.value) {
        await createReviewResponseTemplate({
          clinicId: props.clinicId,
          reviewResponse: templateResponse.value,
          templateName: templateName.value,
        });
        displaySuccess.value = t('reviews.save_template.create_success_message');
        setTimeout(function () {
          displaySuccess.value = null;
          loading.value = false;
          window.location.reload(); // we need to reload page as templates have to be updated on the page
        }, 1500);
      } else {
        await updateReviewResponseTemplate({
          reviewResponse: templateResponse.value,
          reviewResponseTemplateId: selectedTemplate.value.id,
          templateName: templateName.value,
        });
        displaySuccess.value = t('reviews.save_template.default_success_message');
        setTimeout(function () {
          displaySuccess.value = null;
          loading.value = false;
          window.location.reload(); // we need to reload page as templates have to be updated on the page
        }, 1500);
      }
    }
  } catch (error) {
    console.log('Error object:', error);
    errorMessage.value = error.message;
    setTimeout(function () {
      errorMessage.value = null;
    }, 1500);
  }
};
</script>
