<template>
  <div class="relative mx-auto flex h-full w-full items-center justify-center">
    <div class="absolute top-0 z-[5]">
      <radial-progress
        class="-rotate-90"
        :diameter="diameter"
        :completed-steps="1"
        :total-steps="2"
        :start-color="bgColor"
        :stop-color="bgColor"
        inner-stroke-color="rgba(0,0,0,0)"
        :stroke-width="strokeWidth"
        :inner-stroke-width="innerStrokeWidth"
        :animate-speed="animateSpeed"
        :timing-func="timingFunc"
        :is-clockwise="isClockwise"
      >
      </radial-progress>
    </div>
    <div class="absolute top-0 z-[6]">
      <radial-progress
        class="-rotate-90"
        :diameter="diameter"
        :completed-steps="Math.round(completionPercentage / 2)"
        :total-steps="100"
        :start-color="startColor"
        :stop-color="startColor"
        inner-stroke-color="rgba(0,0,0,0)"
        :stroke-width="strokeWidth"
        :inner-stroke-width="innerStrokeWidth"
        :animate-speed="animateSpeed"
        :timing-func="timingFunc"
        :is-clockwise="isClockwise"
      >
        {{ content }}
      </radial-progress>
    </div>
    <div class="pin-element z-30 hidden" :style="positionStyle"></div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  color: {
    type: String,
    default: '#4691F7',
  },
  bgColor: {
    type: String,
    default: '#d9dff0',
  },
  completionPercentage: {
    type: Number,
    default: 25,
  },
  startColor: {
    type: String,
    default: '#74C140',
  },
  innerStrokeColor: {
    type: String,
    default: '#fff',
  },
  strokeWidth: {
    type: Number,
    default: 10,
  },
  innerStrokeWidth: {
    type: Number,
    default: 10,
  },
  animateSpeed: {
    type: Number,
    default: 1000,
  },
  timingFunc: {
    type: String,
    default: 'ease-in-out',
  },
  isClockwise: {
    type: Boolean,
    default: true,
  },
  diameter: {
    type: Number,
    default: 200,
  },
  content: {
    type: String,
    default: '',
  },
});

const startColor = computed(() => {
  switch (props.completionPercentage) {
    case 0:
      return '#f54e3d'; // red
    case 25:
      return '#f54e3d'; // red
    case 50:
      return '#F4C655'; // yellow
    case 75:
      return '#4691F7'; // blue
    case 100:
      return '#74C140'; // green
    default:
      return '#d9dff0'; // grey
  }
});

const radius = computed(() => {
  return props.diameter / 2 - props.strokeWidth;
});

const angleRadians = computed(() => {
  return (Math.PI * props.completionPercentage) / 100; // on half circle
});

const yPosition = computed(() => {
  const centerY = props.diameter / 2;
  const yFromCircleCenter = radius.value * Math.sin(angleRadians.value);
  const top = centerY - 10 - yFromCircleCenter;
  return top;
});

const xPosition = computed(() => {
  const centerX = props.diameter / 2;
  const xFromCircleCenter = radius.value * Math.cos(angleRadians.value);
  const left = centerX - xFromCircleCenter + props.diameter / 10 + 5;
  return left;
});

const positionStyle = computed(() => {
  const position = `left: ${xPosition.value}px; top: ${yPosition.value}px;`;
  return position;
});
</script>

<style scoped>
.pin-element {
  width: 20px; /* Adjust the size of the moving element */
  height: 20px; /* Adjust the size of the moving element */
  background-color: white; /* Set the color of the moving element */
  z-index: 40;
  border-radius: 50%;
  border: solid 2px #d9dff0;
  position: absolute;
  transform: rotate(0deg);
  opacity: 1;
}
</style>
