<template>
  <div>
    <button
      id="dropdownSearchButton"
      data-dropdown-toggle="dropdownSearch"
      data-dropdown-placement="bottom"
      class="focus:shadow-focus flex h-10 w-44 shrink items-center justify-between rounded-lg border border-gray-300 bg-white px-4 text-center text-xs font-medium text-gray-500 hover:drop-shadow-md"
      type="button"
    >
      {{ tagsPlaceholder }}
      <svg class="ms-3 h-2.5 w-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
      </svg>
    </button>
    <div id="dropdownSearch" class="z-10 hidden w-60 rounded-lg bg-white shadow dark:bg-gray-700">
      <div class="p-3">
        <label for="input-group-search" class="sr-only">Search</label>
        <div class="relative">
          <div class="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3">
            <svg
              class="h-4 w-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            id="input-group-search"
            v-model="searchTerm"
            type="text"
            class="flex h-10 w-full shrink items-center justify-between rounded-lg border border-gray-300 bg-white px-4 text-center text-xs font-medium text-gray-500"
            :placeholder="$t('reviews.filter.search_tag')"
            @input="filterTags"
          />
        </div>
      </div>
      <ul class="h-48 overflow-y-auto pb-3 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownSearchButton">
        <li v-for="(tag, index) in displayedTags" :key="index" class="flex items-center justify-between px-4 py-2 hover:bg-gray-100">
          <label class="flex items-center space-x-3">
            <input
              v-model="selectedTags"
              type="checkbox"
              :value="tag"
              class="form-checkbox h-4 w-4 cursor-pointer rounded bg-gray-lighter text-blue focus:outline-blue"
            />
            <span>{{ _.capitalize(tag) }}</span>
          </label>
        </li>
      </ul>
    </div>
    <input v-model="selectedTags" name="tags_name" type="hidden" />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  tags: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
  filterByTags: {
    type: Array,
    required: true,
  },
});

const searchTerm = ref('');
const displayedTags = ref(props.tags);
const selectedTags = ref([...props.filterByTags]);

watch(
  () => props.filterByTags,
  newTags => {
    selectedTags.value = [...newTags];
  },
);

const filterTags = () => {
  displayedTags.value = props.tags.filter(tag => tag.toLowerCase().startsWith(searchTerm.value.toLowerCase()));
};

const tagsPlaceholder = computed(() => {
  if (selectedTags.value.length === 0) {
    return t('reviews.filter.choose_tags');
  } else if (selectedTags.value.length === 1) {
    return t('reviews.filter.tag_selected');
  } else {
    return t('reviews.filter.tags_selected', selectedTags.value.length);
  }
});
</script>
