<template>
  <div class="mb-10 flex w-full flex-col items-start">
    <div class="mb-4 flex w-full flex-wrap gap-3">
      <h1 :class="`text-sm text-primary`">{{ $t(title) }}</h1>
      <div class="flex lg:pl-5">
        <StarsDisplay :rating-sentiment="ratingSentiment" />
      </div>
    </div>

    <div class="w-full lg:px-4">
      <div v-for="(template, index) in templates" :key="index" class="group relative mb-4 flex w-full flex-row rounded-lg bg-gray-light shadow">
        <div :class="`mr-2 w-8 flex-shrink-0 rounded-l-lg ${colorClass}`"></div>
        <div class="flex w-full items-center p-4">
          <p class="flex-1 text-sm text-gray-dark">
            {{ template.content }}
          </p>

          <div class="ml-2 opacity-0 group-hover:block group-hover:opacity-100">
            <button
              :data-tooltip-target="cantDestroy ? `delete-template-tooltip_${template.id}` : null"
              :data-tooltip-placement="cantDestroy ? `bottom` : null"
              :class="['ml-3', cantDestroy ? 'text-gray' : 'text-primary']"
              :disabled="cantDestroy"
              @click="deleteTemplate(template)"
            >
              <hero-icon name="TrashHeroIcon" classes="h-4 w-4" size="default"></hero-icon>
            </button>

            <div
              v-if="cantDestroy"
              :id="`delete-template-tooltip_${template.id}`"
              role="tooltip"
              class="tooltip invisible absolute z-10 inline-block w-1/3 rounded-lg bg-primary px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300"
            >
              {{ $t('automatic_google_review_response.delete_template_tool_tip') }}
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <button class="ml-3" @click="editTemplate(template)">
              <hero-icon name="PencilHeroIcon" classes="h-4 w-4 text-primary" size="default"></hero-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full lg:px-4">
      <div v-if="templates.length < 3" class="w-full space-y-2 px-2">
        <div
          v-for="index in 3 - templates.length"
          :key="index"
          class="flex w-full justify-center rounded-lg border-2 border-dashed border-gray-medium p-2 text-gray"
        >
          <slot>{{ $t('automatic_google_review_response.template_model', { index: index }) }}</slot>
        </div>
      </div>
    </div>

    <div class="flex w-full justify-center">
      <button id="test" class="mt-2 w-full text-gray hover:text-primary" @click="addTemplate">
        {{ $t('automatic_google_review_response.add_template') }}
      </button>
    </div>
  </div>

  <Modal v-model="showReviewResponseTemplateModal" :title="$t(modalTitle)" wrapper-class="modal-wrapper" modal-class="modal modal-lg">
    <AutomaticGoogleReviewReponseTemplatesFormModal
      :template="templateToEdit"
      :clinic-id="clinicId"
      :initial-message="selectedTemplateMessage"
      :initial-template-name="generateNextTemplateName"
      :rating-sentiment="ratingSentiment"
      :template-order="templateToEditOrder"
      @close="showReviewResponseTemplateModal = false"
    />
  </Modal>
  <Modal
    v-model="showDeleteReviewResponseTemplateModal"
    :title="$t('automatic_google_review_response.delete_modal.title')"
    wrapper-class="modal-wrapper"
    modal-class="modal modal-md"
  >
    <ConfirmationModal
      :message="$t('automatic_google_review_response.delete_modal.message')"
      :loading="loading"
      @confirmed="handleDeleteTemplate"
      @close="showDeleteReviewResponseTemplateModal = false"
    />
  </Modal>
</template>
<script setup>
import AutomaticGoogleReviewReponseTemplatesFormModal from '@components/shared/modals/AutomaticGoogleReviewReponseTemplatesFormModal.vue';
import StarsDisplay from '@components/shared/icons/StarsDisplay.vue';
import { ref, computed } from 'vue';
import ConfirmationModal from '../shared/modals/ConfirmationModal.vue';
import { deleteGoogleReviewResponseTemplate } from '@services/reviewService';

const props = defineProps({
  clinicId: { type: Number, required: true },
  title: { type: String, required: true },
  modalTitle: { type: String, required: true },
  ratingSentiment: { type: String, required: true },
  colorClass: { type: String, required: true },
  templates: { type: Array, required: true },
  featureActivated: { type: Boolean, required: true },
});

const selectedTemplateMessage = ref('');
const showReviewResponseTemplateModal = ref(false);
const showDeleteReviewResponseTemplateModal = ref(false);
const templateToEdit = ref(null);
const templateToDelete = ref(null);
const loading = ref(false);

const cantDestroy = computed(() => {
  return (props.featureActivated && props.templates.length <= 3) || loading.value == true;
});

const generateNextTemplateName = computed(() => {
  return `Réponse automatique ${retrieveRatingSentiment()} ${lastTemplateId() + 1}`;
});

const templateToEditOrder = computed(() => {
  return templateToEdit.value ? templateToEdit.value.order : lastTemplateOrder() + 1;
});

const lastTemplateOrder = () => {
  if (props.templates.length === 0) {
    return 0;
  } else {
    return props.templates[props.templates.length - 1].order;
  }
};

const lastTemplateId = () => {
  if (props.templates.length === 0) {
    return 0;
  } else {
    return props.templates[props.templates.length - 1].id;
  }
};

const retrieveRatingSentiment = () => {
  switch (props.ratingSentiment) {
    case 'positive':
      return 'Positive';
    case 'negative':
      return 'Negative';
    default:
      return 'Neutre';
  }
};

const addTemplate = () => {
  selectedTemplateMessage.value = '';
  showReviewResponseTemplateModal.value = true;
};

const editTemplate = template => {
  selectedTemplateMessage.value = template.content;
  showReviewResponseTemplateModal.value = true;
  templateToEdit.value = template;
};

const deleteTemplate = template => {
  showDeleteReviewResponseTemplateModal.value = true;
  templateToDelete.value = template;
};

const handleDeleteTemplate = async () => {
  loading.value = true;
  try {
    await deleteGoogleReviewResponseTemplate({ googleReviewResponseTemplateId: templateToDelete.value.id });
  } catch (error) {
    // dont manage display error message, page will reload with flash message if so
    console.log(error);
  }
  window.location.reload();
};
</script>
