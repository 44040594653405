<template>
  <div class="w-full">
    <form class="box-border flex w-full flex-wrap items-center justify-start gap-2" name="filterForm" method="get" :action="path">
      <input
        type="search"
        class="focus:shadow-focus inline-flex h-10 shrink-0 overflow-hidden rounded-lg border-gray-300 bg-white px-4 text-xs font-medium hover:drop-shadow-md"
        :placeholder="$t('common.search.placeholder')"
        :name="searchName"
        :value="searchKeyword"
      />

      <select
        v-model="filterBy"
        name="filter"
        class="focus:shadow-focus form-select inline-flex h-10 w-64 shrink overflow-hidden rounded-lg border-gray-300 bg-white pl-3 pr-2 text-xs font-medium text-gray-500 hover:drop-shadow-md"
      >
        <option value="" selected>{{ $t('survey_reviews.filter.title') }}</option>
        <option value="with_patient_and_email">{{ $t('survey_reviews.filter.with_patient_email') }}</option>
        <option value="with_patient_entry">{{ $t('survey_reviews.filter.with_patient_entry') }}</option>
        <option value="without_patient_entry">{{ $t('survey_reviews.filter.without_patient_entry') }}</option>
        <option value="with_response">{{ $t('survey_reviews.filter.with_response') }}</option>
        <option value="without_response">{{ $t('survey_reviews.filter.without_response') }}</option>
        <option value="with_comment">{{ $t('survey_reviews.filter.with_comment') }}</option>
        <option value="without_comment">{{ $t('survey_reviews.filter.without_comment') }}</option>
      </select>
      <div class="w-48">
        <vue-tailwind-datepicker
          v-model="dateValue"
          i18n="fr"
          as-single
          use-range
          separator=" à "
          :formatter="formatter"
          :shortcuts="false"
          :options="options"
          :auto-apply="true"
          placeholder="Choisir une période"
          input-classes="block focus:shadow-focus h-10 rounded-lg border-gray-300 bg-white text-xs font-medium text-gray-500 hover:drop-shadow-md"
        />
      </div>
      <input name="after" :value="dateValue.startDate" class="hidden" />
      <input name="before" :value="dateValue.endDate" class="hidden" />
      <MultiSelectTagsInput v-if="displayTags" :filter-by-tags="filterByTags" :tags="tags" />
      <button
        type="submit"
        class="h-10 shrink-0 overflow-hidden rounded-lg border-none bg-primary px-6 text-center text-sm font-medium text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-gray-light disabled:text-textLight"
        :disabled="disableActions"
      >
        {{ $t('common.buttons.submit') }}
      </button>
      <button
        class="hidden h-10 rounded-lg border-none bg-transparent px-2 text-sm text-gray-400 hover:text-blue-500 disabled:cursor-not-allowed sm:inline"
        :disabled="disableActions"
        @click="clearFilter"
      >
        {{ $t('common.buttons.clear_filter') }}
      </button>
      <button
        type="submit"
        class="inline-flex h-10 items-center rounded-lg border-none bg-transparent pt-0 text-gray-400 hover:text-blue-500 hover:drop-shadow-md disabled:cursor-not-allowed sm:hidden"
        data-tooltip-target="tooltip-erase-filter-button"
        data-tooltip-placement="bottom"
        :disabled="disableActions"
        @click="clearFilter"
      >
        <hero-icon name="TrashHeroIcon" size="default"></hero-icon>
      </button>
      <div
        id="tooltip-erase-filter-button"
        role="tooltip"
        class="tooltip invisible absolute z-10 inline-block rounded-lg bg-primary px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-gray-700 lg:hidden"
      >
        Effacer
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import VueTailwindDatepicker from 'vue-tailwind-datepicker';
import MultiSelectTagsInput from '../inputs/MultiSelectTagsInput';

defineProps({
  path: {
    type: String, // google_reviews or reviews
    required: true,
  },
  displayTags: {
    type: Boolean,
    default: false,
  },
  disableActions: {
    type: Boolean,
    default: false,
  },
  esatis1Active: {
    type: Boolean,
    default: false,
  },
  esatis2Active: {
    type: Boolean,
    default: false,
  },
  tags: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
});

const searchKeyword = ref('');
const filterBy = ref('');
const filterByTags = ref([]);
const searchName = ref('q[patient_email_cont]');
const dateValue = ref({
  startDate: '',
  endDate: '',
});
const formatter = ref({
  date: 'DD/MM/YYYY',
  month: 'MMM',
});
const options = ref({
  shortcuts: {
    today: "Aujourd'hui",
    yesterday: 'Hier',
    past: period => `${period} derniers jours`,
    currentMonth: 'Mois en cours',
    pastMonth: 'Mois précédent',
  },
  footer: {
    apply: 'Appliquer',
    cancel: 'Annuler',
  },
});

onMounted(() => {
  const params = new URL(location.href).searchParams;
  searchKeyword.value = params.get(searchName.value) || '';
  filterBy.value = params.get('filter') || '';
  filterByTags.value = params.get('tags_name') ? params.get('tags_name').split(',') : [];
  dateValue.value = {
    startDate: params.get('after') || '',
    endDate: params.get('before') || '',
  };
});

const clearFilter = () => {
  searchKeyword.value = '';
  filterBy.value = '';
  filterByTags.value = [];
  dateValue.value = {
    startDate: '',
    endDate: '',
  };
  document.filterForm.reset();
};
</script>
