<template>
  <div class="flex flex-col">
    <div class="flex w-full justify-end">
      <div
        v-if="errorMessage"
        class="mb-5 flex w-full items-center justify-between border-l-2 border-danger bg-red-100 p-4 text-sm text-danger"
        @click="errorMessage = null"
      >
        <span class="mr-2 text-red-dark">{{ errorMessage }}</span>
        <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer"></hero-icon>
      </div>
      <div
        v-if="displaySuccess"
        class="mb-5 flex w-full items-center justify-between border-l-2 border-green bg-green-100 p-4 text-sm text-success"
        @click="displaySuccess = null"
      >
        <span class="mr-2 text-green-dark">{{ displaySuccess }}</span>
        <hero-icon name="CloseHeroIcon" classes="text-sm hover:cursor-pointer"></hero-icon>
      </div>
    </div>
    <form class="mt-2 text-left" @submit.prevent>
      <h3 class="mb-4 font-medium">{{ $t('reviews.bad.save_reply_as_template') }}</h3>
      <div class="mb-6 rounded-lg bg-blue-100 p-5">
        <LinebreakHTMLWrapper :text="reviewResponse" />
      </div>
      <div class="flex items-center justify-start space-x-3"></div>
      <div class="mt-10 flex items-center justify-end">
        <button
          class="rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:text-primary"
          @click="closeModal"
        >
          {{ $t('buttons.cancel') }}
        </button>
        <button
          class="flex items-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
          :disabled="disableSubmitButton"
          @click="submitTemplate"
        >
          {{ $t('automatic_google_review_response.save_button') }}
        </button>
      </div>
    </form>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { updateGoogleReviewResponseTemplate, createGoogleReviewResponseTemplate } from '@services/reviewService';
import LinebreakHTMLWrapper from '@components/shared/wrapper/LinebreakHTMLWrapper';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['close']);

const props = defineProps({
  template: { type: Object, default: null },
  reviewResponse: {
    type: String,
    required: true,
  },
  initialTemplateName: {
    type: String,
    required: true,
  },
  ratingSentiment: {
    type: String,
    required: true,
  },
  clinicId: {
    type: Number,
    required: true,
  },
  templateOrder: {
    type: Number,
    required: true,
  },
});

const selectedTemplate = ref(null);
const errorMessage = ref(null);
const displaySuccess = ref(null);
const loading = ref(false);

onMounted(() => {
  if (props.template) {
    selectedTemplate.value = props.template;
  }
});

const disableSubmitButton = computed(() => {
  return loading.value === true || sameResponseOnSelectedTemplate.value;
});

const sameResponseOnSelectedTemplate = computed(() => {
  return props.reviewResponse === selectedTemplate?.value?.content;
});

const closeModal = () => {
  emit('close');
};
const submitTemplate = async () => {
  try {
    loading.value = true;
    if (selectedTemplate.value) {
      // Update of a template
      await updateGoogleReviewResponseTemplate({
        googleReviewResponse: props.reviewResponse,
        googleReviewResponseTemplateId: selectedTemplate.value.id,
      });
    } else {
      // Creation of new template
      await createGoogleReviewResponseTemplate({
        clinicId: props.clinicId,
        reviewResponse: props.reviewResponse,
        templateName: props.initialTemplateName,
        ratingSentiment: props.ratingSentiment,
        templateOrder: props.templateOrder,
        mode: 'automatic',
      });
    }
    displaySuccess.value = selectedTemplate.value
      ? t('reviews.save_template.default_success_message')
      : t('reviews.save_template.new_success_message');
    setTimeout(function () {
      displaySuccess.value = null;
      loading.value = false;
      window.location.reload();
    }, 1500);
  } catch (error) {
    errorMessage.value = error.message;
    setTimeout(function () {
      errorMessage.value = null;
    }, 1500);
  }
};
</script>
