<template>
  <div class="flex w-full flex-col overflow-hidden rounded-xl bg-white drop-shadow-md">
    <div class="flex grow flex-col items-start rounded-t-xl bg-primary p-8">
      <div class="survey-header mb-5">
        <h3 class="text-xl font-semibold text-white">{{ capitalizedName }}</h3>
      </div>
      <div class="reviews-number-pill mb-2">
        <span class="mr-2 rounded-full bg-purple-200 px-2.5 py-0.5 text-sm font-semibold text-purple">
          {{ $t('surveys.card.number_of_reviews', { number: surveyReviewsCount }) }}
        </span>
      </div>
      <div class="survey-content mb-6 flex w-full items-center justify-between gap-3">
        <div class="survey-content-text grow">
          <div class="last-response">
            <p class="font-semibold text-white">{{ $t('surveys.card.last_review_submission_date', { date: lastSubmissionDate }) }}</p>
          </div>
        </div>
        <div
          class="group flex items-center justify-center gap-1 rounded-xl bg-yellow px-4 py-2 text-yellow-dark hover:scale-105 hover:drop-shadow-lg"
        >
          <a :href="tabletModePath" class="flex items-center justify-start hover:no-underline">
            <hero-icon name="CreateIonIcon" classes="text-yellow-dark" size="default"></hero-icon>
            <p class="w-12 text-center text-yellow-dark">{{ $t('surveys.card.tablet_mode') }}</p>
          </a>
        </div>
      </div>
    </div>
    <div class="survey-actions grid grid-cols-2 gap-4 rounded-b-xl bg-white px-8 py-6 text-primary">
      <div class="group col-span-1 hover:text-blue-dark hover:drop-shadow-sm">
        <a
          id="previewButton"
          ref="previewButton"
          :href="previewPath"
          class="flex items-center justify-start font-medium text-primary hover:no-underline group-hover:text-blue-dark"
          data-turbo="false"
        >
          <hero-icon v-show="displayActionsIcons" name="EyeHeroIcon" classes="mr-2" size="default"></hero-icon>
          <span id="previewText" ref="previewText" class="font-medium text-primary group-hover:text-blue-dark">{{
            $t('surveys.card.previsualize')
          }}</span>
        </a>
      </div>
      <div class="group col-span-1 flex items-center justify-start hover:text-blue-dark hover:drop-shadow-sm">
        <a :href="synthesisPath" class="flex items-center justify-start font-medium text-primary hover:no-underline group-hover:text-blue-dark">
          <hero-icon v-show="displayActionsIcons" name="ChartBarHeroIcon" classes="mr-2" size="default"></hero-icon>
          <span class="font-medium text-primary group-hover:text-blue-dark">{{ $t('surveys.card.synthesis') }}</span>
        </a>
      </div>
      <div class="group col-span-1 flex items-center justify-start hover:text-blue-dark hover:drop-shadow-sm">
        <a :href="surveyReviewsPath" class="flex items-center justify-start font-medium text-primary hover:no-underline group-hover:text-blue-dark">
          <hero-icon v-show="displayActionsIcons" name="ReaderIonIcon" classes="mr-2" size="default"></hero-icon>
          <span class="font-medium text-primary group-hover:text-blue-dark">{{ $t('surveys.card.see_reviews') }}</span>
        </a>
      </div>
      <div class="col-span-1">
        <!-- Old export without period choice popup -->
        <!--ExportSurveyReviewsGroup :download-survey-reviews-path="downloadSurveyReviewsPath" :display-action-icon="displayActionsIcons"/-->
        <!-- new flow -->
        <ExportReviewsGroup
          :allow-export-for-multiple-clinics="false"
          :export-path="exportSurveyReviewsPath"
          export-response-type="json"
          button-icon-name="CloudDownloadHeroIcon"
          button-classes="group flex items-center justify-start font-medium border-none text-sm bg-transparent text-primary hover:text-blue-dark hover:drop-shadow-sm"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, onBeforeUnmount, ref } from 'vue';
import _ from 'lodash';
// import ExportSurveyReviewsGroup from '../../export/ExportSurveyReviewsGroup.vue';

const props = defineProps({
  survey: {
    type: Object,
    required: true,
  },
  lastSubmissionDate: {
    type: String,
    required: true,
  },
  surveyReviewsCount: {
    type: Number,
    required: true,
  },
  previewPath: {
    type: String,
    required: true,
  },
  synthesisPath: {
    type: String,
    required: true,
  },
  surveyReviewsPath: {
    type: String,
    required: true,
  },
  tabletModePath: {
    type: String,
    required: true,
  },
  exportSurveyReviewsPath: {
    type: String,
    required: true,
  },
});

const displayActionsIcons = ref(true);
const previewButton = ref(null);
const previewText = ref(null);

onMounted(() => {
  // Check if the text is truncated initially
  checkTextTruncation();

  // Listen for window resize events to update text truncation
  window.addEventListener('resize', checkTextTruncation);
});

onBeforeUnmount(() => {
  // Remove the event listener when the component is destroyed
  window.removeEventListener('resize', checkTextTruncation);
});

const capitalizedName = computed(() => {
  return _.capitalize(props.survey.description);
});

const checkTextTruncation = () => {
  // Get the button and text elements
  const button = previewButton.value;
  const text = previewText.value;

  // Check if the text is truncated based on the available space
  const isTruncated = text.scrollWidth > button.clientWidth - 25;

  // Update the data property to reflect the current state
  displayActionsIcons.value = !isTruncated;
};
</script>
