<template>
  <div class="review-card-header mb-4 flex w-full items-center justify-start">
    <div class="avatar relative mr-4 h-10 w-10">
      <div
        v-if="author_not_anonymised"
        class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center overflow-hidden rounded-full dark:bg-gray-600"
        :class="avatarCircleColor"
      >
        <span class="font-medium text-gray-600 dark:text-gray-300">{{ review.author[0] }}</span>
      </div>
      <div v-else class="absolute left-0 top-0 h-10 w-10 overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600">
        <hero-icon name="AvatarHeroIcon" classes="absolute top-1 -left-1 h-12 w-12 text-gray-400"></hero-icon>
      </div>
      <img v-if="review.avatarUrl" :src="review.avatarUrl" class="absolute left-0 top-0 z-10 h-10 w-10 rounded-full" />
    </div>
    <div class="bloc-details">
      <div class="display-name">
        <h3 class="text-sm">{{ review.author }}</h3>
      </div>
      <div class="sub-block-details flex h-full items-center justify-start">
        <div class="mr-4 flex h-full flex-wrap items-center">
          <hero-icon v-for="nb in coloredStarsNb" :key="`coloredStar-${nb}`" name="StarHeroIcon" classes="h-6 pb-2 text-warning"></hero-icon>
          <hero-icon v-for="nb in uncoloredStarsNb" :key="`uncoloredStar-${nb}`" name="StarHeroIcon" classes="h-6 pb-2 text-gray-medium"></hero-icon>
        </div>
        <div class="review-date flex h-full items-center pb-2">
          <p class="text-xs text-textLight">{{ review.reviewDisplayDate }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  // @example:
  // { id:, author:, content:, comment:, avatarURl:, totalRatingNb:, rating:, reviewDisplayDate:, replyDisplayDate:, reply:}
  review: {
    type: Object,
    required: true,
  },
});

const avatarCircleColor = ref('');

onMounted(() => {
  avatarCircleColor.value = _.sample(['bg-gray-400', 'bg-primary', 'bg-secondary', 'bg-indigo', 'bg-info', 'bg-warning']);
});

const author_not_anonymised = computed(() => {
  return props.review.author && props.review.author !== t('patients.anonymous_name');
});

const coloredStarsNb = computed(() => {
  return Math.round(props.review.rating);
});

const uncoloredStarsNb = computed(() => {
  return props.review.totalRatingNb - Math.round(props.review.rating);
});
</script>
