<template>
  <apexchart type="radialBar" :height="height" :options="chartOptions" :series="series[0].data"></apexchart>
</template>

<script setup>
import { computed } from 'vue';
import _ from 'lodash';

const props = defineProps({
  height: {
    type: String,
    default: '100%',
  },
  width: {
    type: String,
    default: '100%',
  },
  tooltipYName: {
    type: String,
    required: false,
    default: '',
  },
  options: {
    type: Object,
    required: true,
  },
  series: {
    type: Array,
    default: () => {
      return [{ data: [75], colors: '#4691F7' }];
    },
  },
});

const chartOptions = computed(() => {
  const merged_options = _.merge(defaultOptions(), props.options);
  return merged_options;
});

const defaultOptions = () => {
  return {
    chart: {
      id: 'gaugechart',
      fontFamily: 'Poppins, sans-serif',
      dropShadow: {
        enabled: false,
      },
    },
    colors: props.series.map(el => el.color),
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      y: {
        formatter: value => `${value} %`,
        title: {
          formatter: () => props.tooltipYName,
        },
      },
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'light',
    //     type: 'horizontal',
    //     shadeIntensity: 1,
    //     gradientToColors: [props.series[0].color],
    //     inverseColors: false,
    //     opacityFrom: 1,
    //     opacityTo: 1,
    //     stops: [0, 100]
    //   }
    // },
    stroke: {
      lineCap: 'round',
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 5,
          size: '50%',
        },
        track: {
          background: '#d9dff0',
          opacity: 0.6,
          // strokeWidth: '80%',
          // dropShadow: {
          //   enabled: true,
          //   top: -3,
          //   left: 0,
          //   blur: 4,
          //   opacity: 0.35
          // }
        },
        dataLabels: {
          showOn: 'always',
          name: {
            offsetY: 45,
            show: true,
            color: '#4691F7',
            fontSize: '12px',
          },
          value: {
            fontSize: '8px',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 400,
            color: '#4691F7',
            offsetY: 0,
            show: true,
            formatter: function (val) {
              return val + '%';
            },
          },
        },
      },
    },
  };
};
</script>
<style scoped></style>
