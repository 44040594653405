<template>
  <div class="font-poppins mx-auto mt-12 flex w-10/12 flex-col items-center lg:w-8/12">
    <h1 class="mb-8 text-4xl">
      {{ capitalizedTitle }}
    </h1>
    <div class="text-md text-left text-textSecondary md:w-1/2">
      {{ welcomeMessage }}
    </div>
    <form
      :action="postFormPath"
      method="post"
      autocomplete="off"
      class="flex w-full flex-col items-center justify-center md:w-1/2"
      data-turbo="false"
    >
      <div class="m-6 flex w-full flex-col">
        <div v-if="displayErrors" class="mb-4 rounded-lg bg-red-50 p-4 text-sm text-red-800 dark:bg-gray-800 dark:text-red-400" role="alert">
          Le formulaire contient des erreurs.
          <ul>
            <li v-for="(error, index) in errors" :key="'error_' + index">{{ error }}</li>
          </ul>
        </div>
        <input type="hidden" name="authenticity_token" :value="csrfToken" />
        <label for="patient_prefix" class="mb-2 text-sm font-bold">Civilité</label>
        <select
          id="patient_prefix"
          v-model="prefix"
          name="patient[prefix]"
          class="form-select mb-3 h-10 w-full overflow-hidden rounded-lg border border-gray-300 bg-white px-4 text-xs font-medium drop-shadow-sm placeholder:text-textSecondary hover:drop-shadow-lg"
        >
          <option value="" selected disabled>{{ $t('patients.edit.civility.placeholder') }}</option>
          <option v-for="(civ, index) in civilities" :key="'civility_' + index" :value="civ">{{ civ }}</option>
        </select>

        <label for="patient_first_name" class="mb-2 text-sm font-bold">Prénom</label>
        <input
          id="patient_first_name"
          v-model="firstName"
          type="text"
          name="patient[first_name]"
          autocomplete="given-name"
          class="form-input mb-3 h-10 w-full overflow-hidden rounded-lg border border-gray-300 bg-white px-4 text-xs font-medium drop-shadow-sm placeholder:text-textSecondary hover:drop-shadow-lg"
        />

        <label for="patient_last_name" class="mb-2 text-sm font-bold">Nom</label>
        <input
          id="patient_last_name"
          v-model="lastName"
          type="text"
          name="patient[last_name]"
          autocomplete="family-name"
          class="form-input mb-3 h-10 w-full overflow-hidden rounded-lg border border-gray-300 bg-white px-4 text-xs font-medium drop-shadow-sm placeholder:text-textSecondary hover:drop-shadow-lg"
        />

        <label for="patient_email" class="required mb-2 text-sm font-bold">Email</label>
        <input
          id="patient_email"
          v-model="email"
          type="email"
          name="patient[email]"
          autocomplete="email"
          required
          class="form-input mb-3 h-10 w-full overflow-hidden rounded-lg border border-gray-300 bg-white px-4 text-xs font-medium drop-shadow-sm placeholder:text-textSecondary hover:drop-shadow-lg"
        />

        <div v-if="displayPhoneInput" class="my-2 flex flex-col">
          <label for="phone_number" class="mb-2 text-sm font-bold">{{ $t('patients.edit.phone_number.placeholder') }}</label>
          <vue-tel-input
            v-model="phoneNumber"
            :input-options="vueTelInputOptions"
            style-classes="h-10 w-full rounded-lg bg-white px-2 text-xs font-medium text-textPrimary drop-shadow-sm hover:drop-shadow-lg"
            @validate="event => validatePhone(event)"
          ></vue-tel-input>
          <p v-if="validPhone === false" class="text-sm text-danger">{{ $t('patients.new.phone_number.invalid_message') }}</p>
        </div>
      </div>

      <input
        type="submit"
        :disabled="disableSubmitButton"
        :value="$t('surveys.tablet.start_survey')"
        class="my-4 h-10 overflow-hidden rounded-lg border-none bg-primary px-6 text-center text-sm font-medium text-white hover:bg-blue-dark hover:drop-shadow-lg disabled:cursor-not-allowed disabled:bg-gray-light disabled:text-textLight"
      />
    </form>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import _ from 'lodash';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  welcomeMessage: {
    type: String,
    required: true,
  },
  displayPhoneInput: {
    type: Boolean,
    required: false,
    default: () => {
      return false;
    },
  },
  errors: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
  civilities: {
    type: Array,
    required: false,
    default: () => {
      return ['M.', 'Mme'];
    },
  },
  postFormPath: {
    type: String,
    required: true,
  },
  patient: {
    type: Object,
    required: false,
    default: () => {
      return {};
    },
  },
});

const prefix = ref('');
const firstName = ref('');
const lastName = ref('');
const phoneNumber = ref('');
const email = ref('');
const vueTelInputOptions = ref({
  styleClasses:
    'bg-transparent rounded-r-lg text-textPrimary placeholder-gray-400 focus:rounded-r-lg target:rounded-r-lg visited:rounded-r-lg hover:rounded-r-lg active:rounded-r-lg autofill:rounded-r-lg',
  name: 'patient[phone_number]',
});
const validPhone = ref(undefined);
const csrfToken = ref('');

onMounted(() => {
  csrfToken.value = document.querySelector('meta[name="csrf-token"]').content;
  prefix.value = props.patient.prefix || '';
  firstName.value = props.patient.first_name || '';
  lastName.value = props.patient.last_name || '';
  email.value = props.patient.email || '';
  phoneNumber.value = props.patient.phone_number || '';
});

const disableSubmitButton = computed(() => {
  return email.value === '' || validPhone.value === false;
});

const displayErrors = computed(() => {
  return props.errors.length > 0;
});

const validatePhone = event => {
  validPhone.value = event.valid;
};

const capitalizedTitle = computed(() => {
  return _.capitalize(props.title);
});
</script>
<style scoped>
.vue-tel-input {
  border-radius: 0.5rem !important;
  display: flex;
  border: 1px solid #d1d5db !important;
  text-align: left;
}
</style>
