<template>
  <div v-for="(line, index) in lines" :key="index" class="text-sm leading-normal text-gray-700">
    <template v-if="line === ''">
      <br />
    </template>
    <template v-else>
      {{ line }}
    </template>
  </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});
const lines = computed(() => {
  return props.text.split('\n');
});
</script>
