<template>
  <apexchart type="pie" :height="height" :options="chartOptions" :series="series.data"></apexchart>
</template>

<script setup>
import { computed } from 'vue';
import _ from 'lodash';

const props = defineProps({
  height: {
    type: String,
    default: '100%',
  },
  width: {
    type: String,
    default: '100%',
  },
  tooltipYName: {
    type: String,
    required: false,
    default: '',
  },
  options: {
    type: Object,
    required: true,
  },
  series: {
    type: Object,
    default: () => {
      return {
        data: [75, 25],
        colors: ['#6cc62f', '#ef8e8e'],
      };
    },
  },
});

const chartOptions = computed(() => {
  const merged_options = _.merge(defaultOptions(), props.options);
  return merged_options;
});

const defaultOptions = () => {
  return {
    chart: {
      id: 'donutChart',
      fontFamily: 'Poppins, sans-serif',
      // dropShadow: {
      //   enabled: false,
      // },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -10,
          minAngleToShowLabel: 10,
        },
      },
    },
    dataLabels: {
      formatter(val) {
        // formatter(val, opts) {
        // const name = opts.w.globals.labels[opts.seriesIndex];
        // const nbValue = opts.w.config.series[opts.seriesIndex];
        return [`${val.toFixed(0)}%`];
      },
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        // colors: ['#AAB1CB'],
      },
    },
    colors: props.series.colors,
    tooltip: {
      enabled: true,
      x: {
        show: true,
      },
      y: {
        // formatter: (value) => `${value} %`,
      },
    },
    legend: {
      position: 'bottom',
      formatter: function (seriesName, opts) {
        return [opts.w.globals.series[opts.seriesIndex], seriesName];
      },
      fontSize: '16px',
    },
    stroke: {
      lineCap: 'round',
    },
  };
};
</script>
<style scoped></style>
