<template>
  <div class="relative w-full animate-pulse overflow-x-auto rounded-lg shadow-md">
    <table class="w-full text-left text-sm text-gray-400">
      <thead>
        <tr class="bg-gray-100 text-center text-white">
          <th class="p-6"><div class="h-4 w-full bg-white"></div></th>
          <th class="p-6"><div class="h-4 w-full bg-white"></div></th>
          <th class="p-6"><div class="h-4 w-full bg-white"></div></th>
          <th class="p-6"><div class="h-4 w-full bg-white"></div></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in [1, 2, 3, 4, 5, 6]" :key="item" class="border-b bg-white">
          <td class="p-6"><div class="h-4 w-full bg-gray-100"></div></td>
          <td class="p-6"><div class="h-4 w-full bg-gray-100"></div></td>
          <td class="p-6"><div class="h-4 w-full bg-gray-100"></div></td>
          <td class="p-6"><div class="h-4 w-full bg-gray-100"></div></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup></script>
<style scoped></style>
