<template>
  <div>
    <a class="cursor-pointer text-blue-600" @click="showModal = true">{{ csvsCount }}</a>
    <Modal
      v-model="showModal"
      :title="$t('user_csvs.modal.title')"
      wrapper-class="modal-wrapper"
      modal-class="modal modal-md max-h-[650px] overflow-auto"
    >
      <div class="bg-scroll">
        <div v-if="csvs.length > 0" class="relative">
          <h3 class="mb-2 font-medium">{{ $t('user_csvs.modal.explanation') }}</h3>
          <div class="relative overflow-x-auto rounded-lg shadow-md">
            <table class="w-full text-left text-sm text-gray-500 dark:text-gray-400">
              <thead class="bg-primary text-center text-white">
                <tr>
                  <th scope="col" class="p-3 text-sm font-medium text-white hover:no-underline">{{ $t('user_csvs.modal.table.csv') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="csv in csvs"
                  :key="csv.id"
                  class="border-b bg-white even:bg-gray-50 hover:bg-blue-100 dark:border-gray-700 dark:bg-gray-800"
                >
                  <td class="py-4 text-center text-textPrimary">{{ formatRailsDate(csv.created_at) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <h3 class="mb-2 font-medium">{{ $t('user_csvs.modal.no_csv') }}</h3>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script setup>
import { ref, computed, watch } from 'vue';

const props = defineProps({
  csvs: {
    type: Array,
    required: true,
  },
});

const showModal = ref(false);
const csvsCount = computed(() => {
  return props.csvs.length;
});

watch(showModal, () => {
  if (showModal.value) {
    document.body.classList.add('overflow-hidden');
  } else {
    document.body.classList.remove('overflow-hidden');
  }
});

const formatRailsDate = railsDatetimeString => {
  const railsDate = new Date(railsDatetimeString);

  const localDate = railsDate.toLocaleString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return localDate;
};
</script>
