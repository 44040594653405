<template>
  <div>
    <div id="flash" class="absolute z-50">
      <div class="fixed right-4 top-20 mt-2 grid grid-cols-1 gap-4">
        <SimpleToast v-show="showSuccess" index="0" model="success" :message="successMessage" @close="handleCloseToast"></SimpleToast>
      </div>
    </div>
    <div v-if="showError" class="mb-4 overflow-hidden rounded-lg bg-red-50 p-4 text-sm text-red-800 dark:bg-gray-800 dark:text-red-400" role="alert">
      {{ t('users.update_notifications.errors', { error: errorMessage }) }}
    </div>
    <form class="" @submit.prevent="regenerateToken">
      <h4 class="block text-sm font-medium text-textSecondary">{{ t('authentication_token.title') }}</h4>
      <div class="mt-4 flex items-center space-x-4">
        <div class="flex w-full flex-wrap">
          <input
            v-model="token"
            type="text"
            readonly
            class="mr-4 w-60 rounded-md border border-gray-light px-3 py-2 shadow-sm focus:border-blue focus:outline-none focus:ring-blue max-sm:my-3 sm:text-sm"
            :disabled="disableActions"
            @click="copyToClipboard"
          />
          <button
            class="relative flex rounded-lg bg-danger px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-red-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-gray-light disabled:text-textLight"
            :disabled="disableSubmitButton"
          >
            <TurningLoader v-if="loading" size="sm" class="mr-2" />
            {{ t('authentication_token.submit') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { updateAuthenticationToken } from '@services/userService';
import { useI18n } from 'vue-i18n';
import SimpleToast from '../toasts/SimpleToast.vue';

const { t } = useI18n();

const props = defineProps({
  authenticationToken: {
    type: String,
    required: true,
  },
});

const loading = ref(false);
const successMessage = ref('');
const showSuccess = ref(false);
const errorMessage = ref('');
const showError = ref(false);
const token = ref(props.authenticationToken);
const disableActions = ref(false);

const regenerateToken = async () => {
  try {
    loading.value = true;
    disableActions.value = true;
    const res = await updateAuthenticationToken();
    if (res) {
      token.value = res.data['authentication_token'];
      displaySuccessMessage();
    }
    disableActions.value = false;
    loading.value = false;
  } catch (error) {
    displayErrrorMessage(error.message);
    loading.value = false;
  }
};

const disableSubmitButton = computed(() => {
  return loading.value;
});

const handleCloseToast = () => {
  showSuccess.value = false;
  successMessage.value = '';
};

const displaySuccessMessage = () => {
  showError.value = false;
  showSuccess.value = true;
  successMessage.value = t('users.update_notifications.success');
};

const displayErrrorMessage = error => {
  showError.value = true;
  errorMessage.value = error;
};

const copyToClipboard = () => {
  const originalValue = token.value;
  disableActions.value = true;
  navigator.clipboard.writeText(originalValue).then(() => {
    token.value = 'Copié !';

    setTimeout(() => {
      token.value = originalValue;
      disableActions.value = false;
    }, 2000);
  });
};
</script>
