<template>
  <div class="bg-scroll">
    <div class="relative">
      <h3 class="mb-2 font-medium">{{ $t('reviews.add_response.modal.respond') }}</h3>
      <form class="mt-2" name="review" @submit.prevent>
        <div>
          <div class="flex w-full justify-end">
            <div v-show="showError" class="absolute top-0 rounded-full bg-red-100 px-2 py-1 text-right text-xs">
              <span class="text-xs text-danger">{{ error }}</span>
            </div>
          </div>
          <div class="mb-3 flex flex-row items-center justify-end">
            <button
              data-tooltip-target="tooltip-erase-review-response-button"
              class="mr-1 h-8 rounded-full bg-blue px-3 py-1 text-xs text-white hover:bg-blue-dark disabled:cursor-not-allowed disabled:bg-blue-lighter"
              :disabled="loading"
              @click="eraseReviewResponse"
            >
              <hero-icon name="TrashHeroIcon" size="small" classes="flex items-center"></hero-icon>
            </button>
            <DefaultTooltip id="tooltip-erase-review-response-button" :message="$t('reviews.bad.tooltip_erase')" />
            <button
              data-tooltip-target="tooltip-fill-with-ia-button"
              class="mr-1 h-8 rounded-full bg-blue px-3 text-xs text-white hover:bg-blue-dark disabled:cursor-not-allowed disabled:bg-blue-lighter"
              :disabled="loading"
              @click="fillWithIa"
            >
              {{ $t('reviews.bad.ia_suggestion_button') }}
            </button>
            <div
              id="tooltip-fill-with-ia-button"
              role="tooltip"
              class="tooltip invisible absolute z-30 inline-block rounded-lg bg-primary px-3 py-2 font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-gray-700 lg:hidden"
            >
              {{ $t('reviews.bad.tooltip_suggestion_ia') }}
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <DefaultTooltip id="tooltip-fill-with-ia-button" :message="$t('reviews.bad.tooltip_suggestion_ia')" />
            <button
              data-tooltip-target="tooltip-refresh-ia-button"
              class="mr-1 h-8 rounded-full bg-blue px-3 py-1 text-xs text-white hover:bg-blue-dark disabled:cursor-not-allowed disabled:bg-blue-lighter"
              :disabled="loading"
              @click="refreshIa"
            >
              <hero-icon name="SyncHeroIcon" size="small" classes="flex items-center"></hero-icon>
            </button>
            <DefaultTooltip id="tooltip-refresh-ia-button" :message="$t('reviews.bad.tooltip_refresh_ia')" />
          </div>
        </div>
        <div class="relative mb-6">
          <div v-show="loading" id="spinner_icon" class="absolute flex h-full w-full items-center justify-center">
            <TurningLoader :message="$t('loading')" text-color="text-textLight" />
          </div>
          <textarea
            id="message"
            v-model="reviewResponse"
            rows="4"
            :disabled="loading"
            :autofocus="true"
            name="message"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            :placeholder="$t('reviews.write_response')"
            :class="loading ? 'opacity-20' : ''"
          >
          </textarea>
        </div>
        <div class="mt-10 flex items-center justify-end">
          <button
            class="mr-2 rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:text-primary"
            @click="closeModal"
          >
            {{ $t('buttons.cancel') }}
          </button>
          <button
            class="flex items-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
            :disabled="disableSubmitButton"
            @click="showSaveTemplateModal = true"
          >
            <div class="mr-2">{{ $t('automatic_google_review_response.save_button') }}</div>
            <hero-icon name="PaperPlaneIonIcon" size="small"></hero-icon>
          </button>
        </div>
        <Modal
          v-model="showSaveTemplateModal"
          :title="$t('reviews.save_template.modal.title')"
          wrapper-class="modal-wrapper"
          modal-class="modal modal-lg"
        >
          <AutomaticGoogleReviewReponseTemplatesConfirmationModal
            :template="template"
            :initial-template-name="reviewResponseTemplateName"
            :review-response="reviewResponse"
            :clinic-id="props.clinicId"
            :rating-sentiment="ratingSentiment"
            :template-order="templateOrder"
            @close="showSaveTemplateModal = false"
          />
        </Modal>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import AutomaticGoogleReviewReponseTemplatesConfirmationModal from '@components/shared/modals/AutomaticGoogleReviewReponseTemplatesConfirmationModal.vue';
import { useReviewsStore } from '@stores/reviews.js';

const store = useReviewsStore();

const emit = defineEmits(['close']);

const props = defineProps({
  clinicId: {
    type: Number,
    required: true,
  },
  initialMessage: { type: String, default: '' },
  initialTemplateName: { type: String, default: '' },
  ratingSentiment: { type: String, required: true },
  template: { type: Object, default: null },
  templateOrder: { type: Number, required: true },
});

const reviewResponse = ref(props.initialMessage);
const reviewResponseTemplateName = ref(props.initialTemplateName);
const loading = ref(false);
const error = ref('');
const showError = ref(false);
const showSaveTemplateModal = ref(false);

const disableSubmitButton = computed(() => {
  return reviewResponse.value === '' || loading.value;
});

const eraseReviewResponse = () => {
  reviewResponse.value = '';
  reviewResponseTemplateName.value = '';
};

const fillWithIa = () => {
  let suggestion = store.getGoogleIaSuggestionBySentiment(props.ratingSentiment);
  if (suggestion === null || suggestion.trim() === '') {
    refreshIa();
  } else {
    reviewResponse.value = suggestion;
  }
};

const refreshIa = async () => {
  loading.value = true;
  try {
    const res = await store.fetchAndStoreGoogleSuggestionTemplateIa({ clinicId: props.clinicId, ratingSentiment: props.ratingSentiment });
    loading.value = false;
    if (res.error) {
      handleErrorInFetchingIa(res.error);
    } else {
      reviewResponse.value = res.suggestion;
    }
  } catch (error) {
    loading.value = false;
    handleErrorInFetchingIa(error.message);
  }
};

const handleErrorInFetchingIa = newError => {
  error.value = newError;
  showError.value = true;
  setTimeout(() => (showError.value = false), 4000);
};

const closeModal = () => {
  emit('close');
};
</script>
