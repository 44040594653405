<template>
  <div class="min-h-[120px] w-full animate-pulse rounded-xl bg-transparent p-6">
    <div class="review-card-header mb-4 flex w-full items-center justify-start">
      <div class="avatar relative mr-4 h-10 w-10">
        <div class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-gray-200"></div>
      </div>
      <div class="bloc-details">
        <div class="display-name mb-2 h-4 w-32 bg-gray-200"></div>
        <div class="display-name h-4 w-40 bg-gray-200"></div>
      </div>
    </div>
    <div class="review-card-body">
      <div class="mb-2 h-4 w-full bg-gray-200"></div>
      <div class="h-4 w-full bg-gray-200"></div>
    </div>
  </div>
</template>
<script setup></script>
<style scoped></style>
