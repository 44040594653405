<template>
  <div>
    <div v-if="type == 'multiple_clinics_only'">
      <a
        href="/csvs/new_multiclinics_import"
        data-tooltip-target="tooltip-import-patients-button1"
        type="button"
        class="inline-flex h-10 w-10 overflow-hidden rounded-full bg-primary px-0 text-center hover:bg-blue-dark hover:no-underline hover:drop-shadow-md dark:bg-gray-600 dark:focus:ring-gray-600 lg:w-fit lg:rounded-lg lg:px-4"
      >
        <div class="flex h-full w-full items-center justify-center lg:justify-between">
          <hero-icon name="PlusHeroIcon" classes="text-white dark:text-gray-300 lg:mr-2" size="default"></hero-icon>
          <div class="hidden lg:block">
            <span class="font-semibold text-white dark:text-gray-300">{{ $t('import_patients.button') }}</span>
          </div>
        </div>
      </a>
      <div class="lg:hidden">
        <DefaultTooltip id="tooltip-import-patients-button1" :message="$t('import_patients.button')" />
      </div>
    </div>
    <div v-else>
      <button
        data-tooltip-target="tooltip-import-patients-button"
        type="button"
        class="inline-flex h-10 w-10 overflow-hidden rounded-full bg-primary px-0 text-center hover:bg-blue-dark hover:drop-shadow-md dark:bg-gray-600 dark:focus:ring-gray-600 lg:w-fit lg:rounded-lg lg:px-4"
        aria-controls="import-dropdown"
        aria-expanded="false"
        data-dropdown-toggle="import-dropdown"
        data-dropdown-placement="bottom"
      >
        <span class="sr-only">Open import dropdown</span>
        <div class="flex h-full w-full items-center justify-center lg:justify-between">
          <hero-icon name="PlusHeroIcon" classes="text-white dark:text-gray-300 lg:mr-2" size="default"></hero-icon>
          <div class="hidden lg:block">
            <span class="font-medium leading-4 text-white dark:text-gray-300">{{ $t('import_patients.button') }}</span>
          </div>
        </div>
      </button>
      <div class="lg:hidden">
        <DefaultTooltip id="tooltip-import-patients-button" :message="$t('import_patients.button')" />
      </div>
      <div
        id="import-dropdown"
        class="z-10 hidden w-80 list-none divide-y divide-gray-100 rounded-lg bg-white text-base drop-shadow-2xl dark:divide-gray-600 dark:bg-gray-700"
      >
        <ul aria-labelledby="import-menu-links">
          <li>
            <button
              class="text-md group flex cursor-pointer items-center rounded-t-lg px-6 pb-4 pt-6 text-left text-sm text-textSecondary hover:bg-blue-100 hover:text-primary hover:no-underline dark:text-primary dark:hover:bg-gray-600 dark:hover:text-white"
              @click="navigateToPage('/patients/new')"
            >
              <div class="w-18 mr-4">
                <hero-icon name="UserHeroIcon" size="default"></hero-icon>
              </div>
              <p class="text-sm text-textPrimary group-hover:text-primary">{{ $t('import_patients.manual_import_option') }}</p>
            </button>
          </li>
          <li>
            <button
              class="text-md group flex cursor-pointer items-center px-6 py-4 text-left text-textSecondary hover:bg-blue-100 hover:text-primary hover:no-underline dark:text-primary dark:hover:bg-gray-600 dark:hover:text-white"
              @click="navigateToPage('/csvs/new')"
            >
              <div class="w-18 mr-4">
                <hero-icon name="UsersHeroIcon" size="default"></hero-icon>
              </div>
              <p class="text-sm text-textPrimary group-hover:text-primary">{{ $t('import_patients.file_one_import_option') }}</p>
            </button>
          </li>
          <li>
            <button
              class="text-md group flex cursor-pointer items-center rounded-b-lg px-6 pb-6 pt-4 text-left text-textSecondary hover:bg-blue-100 hover:text-primary hover:no-underline dark:text-primary dark:hover:bg-gray-600 dark:hover:text-white"
              @click="navigateToPage('/csvs/new_multiclinics_import')"
            >
              <div class="w-18 mr-4">
                <hero-icon name="BusinessIonIcon" size="default"></hero-icon>
              </div>
              <p class="text-sm text-textPrimary group-hover:text-primary">{{ $t('import_patients.file_multi_import_option') }}</p>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
defineProps({
  type: {
    type: String, // default, multiple_clinics_only, none
    required: true,
  },
});

const navigateToPage = path => {
  window.location.href = path;
};
</script>
<style scoped></style>
