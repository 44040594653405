<template>
  <div>
    <button :class="buttonClasses" @click="showExportModal = true">
      <hero-icon :name="buttonIconName" classes="pt-1 mr-2 h-6"></hero-icon>
      {{ $t('export.buttons.export_to_excel') }}
    </button>
    <Modal v-model="showExportModal" :title="$t('reviews.bad.title')" wrapper-class="modal-wrapper" modal-class="modal modal-lg">
      <div v-show="isError" class="mb-4 flex items-center justify-between rounded-lg bg-red-50 p-4 text-sm text-danger" role="alert">
        <hero-icon name="AlertOutlinetIonIcon" size="large" classes="pt-1 mr-2"></hero-icon>
        <p class="text-danger">{{ errorMessage || $t('export.alert.export_review_error') }}</p>
      </div>
      <div v-show="isSuccessMessage" class="mb-4 flex items-center justify-between rounded-lg bg-green-50 p-4 text-sm text-green-dark" role="alert">
        <hero-icon name="CheckCircleOutlineHeroIcon" size="large" classes="pt-1 mr-2"></hero-icon>
        <p class="text-green-dark">{{ successMessage }}</p>
      </div>
      <div v-if="isLoading" class="mt-10 text-center">
        <TurningLoader :message="$t('loading')" text-color="text-textLight" />
      </div>
      <div v-else>
        <div class="w-12/12">
          <h3 class="mb-5 font-medium">{{ $t('reviews.bad.select_a_period') }}</h3>
          <div>
            <input id="since_beginning" v-model="periodType" type="radio" value="since_beginning" />
            <label for="since_beginning" class="mx-3">{{ $t('reviews.bad.since_beginning') }}</label>
          </div>
          <div class="mt-2">
            <input id="defined_period" v-model="periodType" type="radio" value="defined_period" />
            <label for="defined_period" class="mx-3">{{ $t('reviews.bad.defined_period') }}</label>
          </div>
          <div v-if="displayDatePickers" class="w-12/12 mb-1 ml-4 mt-2 flex items-center justify-start">
            <label for="start_date" class="mx-3">{{ $t('reviews.bad.start_date') }}</label>
            <div class="relative max-w-sm">
              <vue-tailwind-datepicker
                id="start_date"
                v-model="startDate"
                i18n="fr"
                as-single
                :formatter="formatter"
                :shortcuts="false"
                :auto-apply="true"
                placeholder="jj-mm-aaaa"
                input-classes="block h-10 rounded-lg border-gray-200 bg-white text-xs font-medium text-gray-500 hover:drop-shadow-md"
              />
            </div>
            <label for="end_date" class="mx-3">{{ $t('reviews.bad.end_date') }}</label>
            <div class="relative max-w-sm">
              <vue-tailwind-datepicker
                id="end_date"
                v-model="endDate"
                i18n="fr"
                as-single
                :formatter="formatter"
                :shortcuts="false"
                :auto-apply="true"
                placeholder="jj-mm-aaaa"
                input-classes="block h-10 rounded-lg border-gray-200 bg-white text-xs font-medium text-gray-500 hover:drop-shadow-md"
              />
            </div>
          </div>
          <div v-else class="w-12/12 my-10"></div>
        </div>
        <div v-if="allowExportForMultipleClinics" class="w-12/12 pt-6">
          <h3 class="mb-5">{{ $t('reviews.bad.select_clinic') }}</h3>
          <input id="export_current_clinic" v-model="exportType" type="radio" value="current_clinic" />
          <label for="export_current_clinic" class="mx-3">{{ $t('reviews.bad.export_current_clinic') }}</label>
          <br />
          <input id="export_multiple_clinics" v-model="exportType" type="radio" value="user_clinics" />
          <label for="export_multiple_clinics" class="mx-3">{{ $t('reviews.bad.export_multiple_clinics') }}</label>
          <br />
        </div>
      </div>
      <div class="mt-10 flex items-center justify-end">
        <button
          class="rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:text-primary"
          @click="showExportModal = false"
        >
          {{ $t('buttons.cancel') }}
        </button>
        <button
          class="rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
          :disabled="formIsInvalid || isLoading"
          @click="handleExport"
        >
          {{ $t('reviews.bad.submit_export') }}
        </button>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { exportFile, exportFileByEmail } from '@services/exportService';
import VueTailwindDatepicker from 'vue-tailwind-datepicker';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
  allowExportForMultipleClinics: {
    type: Boolean,
    required: true,
  },
  exportPath: {
    type: String,
    required: true,
  },
  buttonClasses: {
    type: String,
    default:
      'flex items-center rounded-full border border-transparent bg-transparent px-3 py-2 text-sm text-textSecondary hover:border-primary hover:text-primary',
    required: false,
  },
  buttonIconName: {
    type: String,
    default: 'cloud-download-outline',
    required: false,
  },
  exportResponseType: {
    type: String,
    default: 'file', // allowed values: 'file', 'json'
    required: false,
  },
});

const showExportModal = ref(false);
const isLoading = ref(false);
const isError = ref(false);
const isSuccessMessage = ref(false);
const errorMessage = ref(null);
const successMessage = ref(null);
const displayDatePickers = ref(false);
const startDate = ref('');
const endDate = ref('');
const periodType = ref('since_beginning');
const exportType = ref('current_clinic'); // or user_clinics
const formatter = ref({ date: 'DD-MM-YYYY', month: 'MMM' });

const formIsInvalid = computed(() => {
  return periodType.value === 'defined_period' && (isBlank(startDate.value) || isBlank(endDate.value) || startDate.value >= endDate.value);
});

const isBlank = value => {
  return value == null || /^\s*$/.test(value);
};

watch(periodType, newPeriodType => {
  displayDatePickers.value = newPeriodType === 'defined_period';
});

watch([startDate, endDate], ([newStartDate, newEndDate]) => {
  if (newEndDate && newStartDate && newEndDate <= newStartDate) {
    displayErrorEndDateMessage();
  }
});

const displayErrorEndDateMessage = () => {
  isError.value = true;
  errorMessage.value = t('reviews.bad.error_end_date_must_be_after_start_date');
  setTimeout(() => {
    isError.value = false;
    errorMessage.value = null;
  }, 3000);
};

const handleExport = async () => {
  try {
    isLoading.value = true;
    const path = `${props.exportPath}?export_type=${exportType.value}&period_type=${periodType.value}&start_date=${startDate.value}&end_date=${endDate.value}`;
    let response = null;
    if (props.exportResponseType === 'file') {
      await exportFile(path);
    } else {
      response = await exportFileByEmail(path);
    }
    if (response) {
      successMessage.value = response.data.message;
      isSuccessMessage.value = true;
      setTimeout(() => {
        isSuccessMessage.value = false;
        successMessage.value = null;
        isLoading.value = false;
        showExportModal.value = false;
      }, 3000);
    } else {
      isLoading.value = false;
      showExportModal.value = false;
    }
  } catch (error) {
    errorMessage.value = error.message;
    isError.value = true;
    isLoading.value = false;
    setTimeout(() => {
      isError.value = false;
      errorMessage.value = null;
    }, 3000);
  }
};
</script>
