<template>
  <component :is="componentData.component" v-bind="componentData.props"><slot /></component>
</template>

<script>
export default {
  name: 'DataWrapper',
  data() {
    return {
      componentData: {},
    };
  },
  created() {
    this.componentData = JSON.parse(this.$attrs.data);
  },
};
</script>
