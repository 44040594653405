<template>
  <apexchart :width="width" :height="height" :options="chartOptions" :series="series"></apexchart>
</template>

<script setup>
import { computed } from 'vue';
import _ from 'lodash';
import fr from 'apexcharts/dist/locales/fr.json';

const props = defineProps({
  height: {
    type: String,
    default: '100%',
  },
  width: {
    type: String,
    default: '100%',
  },
  options: {
    type: Object,
    required: true,
  },
  enableDatalabels: {
    type: Boolean,
    default: false,
    required: false,
  },
  enablePercentageLabels: {
    type: Boolean,
    default: false,
    required: false,
  },
  tooltipYName: {
    type: String,
    required: false,
    default: '',
  },
  enableTools: {
    type: Boolean,
    required: false,
    default: false,
  },
  series: {
    type: Array,
    default: () => {
      return [{ name: 'Note', data: [1.0, 3.5, 2.8, 4.3], color: '#4691F7' }];
    },
  },
});

const adjust = (color, amount) => {
  return (
    '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
  );
};

const chartOptions = computed(() => {
  let merged_options = defaultOptions();
  if (props.enableDatalabels === true) {
    merged_options.dataLabels = dataLabels;
  }
  if (props.enablePercentageLabels === true) {
    merged_options.yaxis.labels = percentageYaxisLabels;
    merged_options.yaxis.tickAmount = 5;
  }
  let final_options = _.merge(merged_options, props.options);
  final_options.xaxis.categories = props.options.xaxis.categories || merged_options.xaxis.categories;
  return final_options;
});

const isMultiseries = computed(() => {
  return props.series.length > 1;
});

const percentageYaxisLabels = {
  formatter: val => {
    return `${val} %`;
  },
};

const dataLabels = {
  enabled: true,
  formatter: function (value, { seriesIndex, dataPointIndex, w }) {
    const currentPoint = w.config.series[seriesIndex].data[dataPointIndex];
    const previousPoint = w.config.series[seriesIndex].data[dataPointIndex - 1];
    if (isMultiseries.value) {
      return previousPoint && currentPoint['y'] !== previousPoint['y'] ? value : '';
    } else {
      return currentPoint !== previousPoint ? value : '';
    }
  },
  offsetX: 0,
  offsetY: -10,
  style: {
    fontSize: 14,
    colors: props.series.map(serie => adjust(serie.color, -30)),
  },
  background: {
    enabled: false,
    foreColor: '#8b92a9',
  },
};

const defaultOptions = () => {
  const months = ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'];
  let d = new Date();
  let currentMonth = d.getMonth();
  return {
    // responsive: [
    //   {
    //     breakpoint: 768,
    //     options: {
    //       chart: {
    //         width: 100,
    //         height: 3,
    //       },
    //     },
    //   },
    // ],
    chart: {
      id: 'columnchart',
      type: 'bar',
      locales: [fr],
      defaultLocale: 'fr',
      sparkline: {
        enabled: false,
      },
      zoom: {
        autoScaleYaxis: true,
      },
      fontFamily: 'Poppins, sans-serif',
      dropShadow: {
        enabled: false,
      },
      toolbar: {
        show: props.enableTools,
        offsetY: -20,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
      },
      followCursor: true,
      y: {
        formatter: undefined,
        title: {
          formatter: seriesName => {
            if (isMultiseries.value) {
              return `${props.tooltipYName} ${seriesName} :`;
            } else {
              return props.tooltipYName ? props.tooltipYName : seriesName;
            }
          },
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#ef8e8e'],
      },
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px',
      fontFamily: 'Poppins, Arial',
      fontWeight: 200,
      formatter: undefined,
      inverseOrder: false,
      // width: 100,
      height: 100,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 2,
        strokeColor: '#fff',
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    fill: {
      opacity: 1,
    },
    stroke: {
      show: true,
      width: 0,
      colors: ['transparent'],
    },
    grid: {
      show: true,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 10,
        right: 10,
        top: 5,
        bottom: 0,
      },
    },
    xaxis: {
      type: 'Month',
      categories: [months[currentMonth - 3], months[currentMonth - 2], months[currentMonth - 1], months[currentMonth]],
      axisBorder: {
        show: true,
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: true,
      },
      title: {
        text: undefined,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '12px',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 600,
          cssClass: 'apexcharts-xaxis-title',
        },
      },
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          colors: [],
          // fontSize: '12px',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-label',
        },
        offsetX: 0,
        offsetY: 0,
        format: undefined,
        // formatter: function (value) {
        //   return value;
        // },
        datetimeUTC: true,
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yy",
          day: 'dd MMM',
          hour: 'HH:mm',
        },
      },
    },
    yaxis: {
      type: 'Note',
      show: true,
      title: {
        text: undefined,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '12px',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 600,
          cssClass: 'apexcharts-xaxis-title',
        },
      },
      labels: {
        show: true,
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: true,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          colors: [],
          fontSize: '14px',
          fontFamily: 'Poppins, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-label',
        },
        offsetX: -4,
        offsetY: 0,
        format: undefined,
        formatter: function (value) {
          return _.round(value, 1);
        },
        datetimeUTC: true,
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yy",
          day: 'dd MMM',
          hour: 'HH:mm',
        },
      },
    },
  };
};
</script>
<style scoped></style>
