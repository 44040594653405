<template>
  <div class="font-poppins relative w-full pb-6 text-gray-dark drop-shadow-[0_2px_0_rgb(0,0,0,0.03)] md:pb-12">
    <section id="header" class="fixed z-30 w-full bg-bgLight pt-6" :class="layout === 'application' ? 'px-6 md:px-12 md:pt-8' : ''">
      <div class="header-upper-block items center mb-4 flex justify-between">
        <div class="items center mb-4 flex justify-start gap-8">
          <div v-if="showReturn" class="relative">
            <a
              data-tooltip-target="tooltip-back"
              data-tooltip-placement="bottom"
              class="flex h-8 w-8 items-center justify-center rounded-lg bg-blue-300 hover:bg-blue hover:drop-shadow-sm"
              @click="goBack"
            >
              <hero-icon name="ChevronLeftIonIcon" size="default" classes="text-white"></hero-icon>
            </a>
            <DefaultTooltip id="tooltip-back" classes="bg-blue-200 text-blue-500" :message="$t('return')" />
          </div>
          <h2>{{ title }}</h2>
        </div>
      </div>
      <div>
        <ul class="flex flex-wrap gap-3 text-center text-base font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400">
          <li v-for="(tab, index) in tabs" :key="index" class="me-2">
            <a
              :href="tab.path"
              :class="[
                'inline-block rounded-t-lg p-2 hover:border-b-4 hover:border-primary hover:bg-gray-light hover:text-primary hover:no-underline dark:text-white dark:hover:bg-gray-700 2xl:text-lg',
                tab.active ? 'border-b-4 border-primary fill-primary text-primary' : 'border-0 fill-secondary text-textSecondary',
              ]"
              :aria-current="tab.active ? 'page' : undefined"
            >
              {{ tab.name }}
            </a>
          </li>
        </ul>
      </div>
    </section>
    <slot />
  </div>
</template>

<script setup>
const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  // [{ name: 'Tab 1', path: '/path1', active: true }, { name: 'Tab 2', path: '/path2', active: false }]
  // Exemple in clinic_settings_controller.rb
  title: {
    type: String,
    required: true,
  },
  showReturn: {
    type: Boolean,
    default: false,
  },
  layout: {
    type: String,
    default: 'application',
  },
  beforePath: {
    type: String,
    default: '/',
  },
});

const goBack = () => {
  window.location.href = props.beforePath;
};
</script>
