<template>
  <div class="flex space-x-1">
    <StarRating :rating="rating1" />
  </div>
  <span v-if="rating2" class="mx-2">ou</span>
  <div v-if="rating2" class="flex space-x-1">
    <StarRating :rating="rating2" />
  </div>
</template>
<script setup>
import { defineProps } from 'vue';
import StarRating from '@components/shared/progress/StarRating.vue';

const props = defineProps({
  ratingSentiment: { type: String, required: true },
});

const getRatings = sentiment => {
  switch (sentiment) {
    case 'negative':
      return { rating1: 1, rating2: 2 };
    case 'neutral':
      return { rating1: 3, rating2: null };
    case 'positive':
      return { rating1: 4, rating2: 5 };
    default:
      return { rating1: 0, rating2: 0 };
  }
};

const { rating1, rating2 } = getRatings(props.ratingSentiment);
</script>
