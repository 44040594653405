<template>
  <div>
    <simple-toast v-show="showError1" index="1" model="danger" :message="errorMessage1" @close="handleCloseAlert1"></simple-toast>
    <button
      class="bg-transparent, group flex items-center rounded-full border border-primary px-3 py-2 text-sm text-primary hover:bg-primary hover:text-white hover:drop-shadow-md"
      @click="showAddUserModal = true"
    >
      <hero-icon name="PlusHeroIcon" classes="text-primary group-hover:text-white dark:text-gray-300 lg:mr-2" size="default"></hero-icon>
      {{ $t('users.add_button') }}
    </button>
    <Modal v-model="showAddUserModal" :title="$t('users.add_button')" wrapper-class="modal-wrapper" modal-class="modal modal-lg">
      <div class="flex w-full flex-col">
        <div v-if="step === 1">
          <div v-if="showClinicsDropdown">
            <h3 class="mb-4 text-sm text-textSecondary">{{ $t('users.multi_clinics_message') }}</h3>
          </div>
          <div v-else>
            <h3 class="mb-4 text-sm text-textSecondary">{{ $t('users.one_clinic_message') }}</h3>
          </div>
          <div
            v-if="showError1"
            class="mb-5 flex w-full items-center justify-between border-l-2 border-danger bg-red-100 p-4 text-sm text-danger"
            @click="handleCloseAlert1"
          >
            <span class="mr-2 text-red-dark">{{ errorMessage1 }}</span>
            <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer"></hero-icon>
          </div>
          <form class="mt-2 text-left" @submit.prevent>
            <div class="form-group mb-4">
              <label class="mb-2 block font-medium tracking-wide text-gray-700">{{ $t('users.clinics_label') }}</label>
              <div class="flex items-center justify-start space-x-3">
                <v-select
                  v-model.trim="$v.selectedClinics.$model"
                  multiple
                  autocomplete
                  :close-on-select="false"
                  :deselect-from-dropdown="true"
                  :options="clinics"
                  :reduce="clinic => clinic['id']"
                  label="name"
                  class="w-full"
                />
                <div v-if="$v.selectedClinics.validArray.$invalid">
                  <p class="text-xs leading-normal text-gray-700">{{ $t('users.required') }}</p>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="mb-2 block font-medium tracking-wide text-gray-700">{{ $t('users.email_label') }}</label>
              <div class="flex items-center justify-start">
                <input
                  v-model.trim="$v.userEmail.$model"
                  class="form-input mb-2 mr-2 inline-flex h-10 w-80 shrink-0 overflow-hidden rounded-lg border border-gray-300 bg-white px-4 text-xs font-medium hover:drop-shadow-md"
                  :class="{ 'border-red': $v.userEmail.$error }"
                  placeholder="john@example.com"
                  @blur="$v.userEmail.$touch"
                />
                <div v-if="$v.userEmail.required.$invalid" class="text-xs leading-normal text-gray-700">{{ $t('users.required') }}</div>
              </div>
              <div v-if="$v.userEmail.email.$invalid" class="text-xs leading-normal text-danger">{{ $t('users.email_format_error') }}</div>
            </div>
            <div class="form-group mt-4 block">
              <label class="mb-2 block font-medium tracking-wide text-gray-700">{{ $t('users.role_label') }}</label>
              <div v-for="role in roles" :key="role">
                <label class="">
                  <input v-model="$v.selectedRole.$model" type="radio" class="form-radio" name="role" :value="role" />
                  <span class="ml-2">{{ $t(`users.role_${role}`) }}</span>
                </label>
              </div>
              <div v-if="$v.selectedRole.required.$invalid" class="mt-1 text-xs text-gray-700">{{ $t('users.required') }}</div>
            </div>
            <div class="mt-10 flex items-center justify-end">
              <button
                class="rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:text-primary"
                @click="closeModal"
              >
                {{ $t('buttons.cancel') }}
              </button>
              <button
                class="flex items-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
                :disabled="$v.$invalid"
                @click="handleAddUser"
              >
                <div v-if="buttonNextIsLoading">{{ $t('users.loading_button') }}</div>
                <div v-else>{{ $t('users.next_button') }}</div>
              </button>
            </div>
          </form>
        </div>
        <div v-else-if="step === 2">
          <h3 class="mb-4 font-medium">{{ $t('users.fill_in_message') }}</h3>
          <div
            v-if="showError2"
            class="mb-5 flex w-full items-center justify-between border-l-2 border-danger bg-red-100 p-4 text-sm text-danger"
            @click="handleCloseAlert2"
          >
            <span class="mr-2 text-red-dark">{{ errorMessage2 }}</span>
            <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer"></hero-icon>
          </div>
          <form class="mt-4 text-left" @submit.prevent>
            <div class="form-group mb-4">
              <label class="mb-2 block font-medium tracking-wide text-gray-700">{{ $t('users.first_name_label') }}</label>
              <div class="flex items-center justify-start">
                <input
                  v-model.trim="$v.firstName.$model"
                  class="form-input mb-2 mr-2 inline-flex h-10 w-80 shrink-0 overflow-hidden rounded-lg border-gray-300 bg-white px-4 text-xs font-medium hover:drop-shadow-md"
                  :class="{ 'border-red': $v.firstName.$error }"
                />
                <div v-if="$v.firstName.required.$invalid" class="text-xs leading-normal text-gray-400">{{ $t('users.required') }}</div>
              </div>
              <div v-if="$v.firstName.minLength.$invalid" class="text-xs leading-normal text-danger">
                {{ $t('users.first_name_min_length_error_message') }} {{ $v.firstName.minLength.$params.min }} {{ $t('users.letters') }}.
              </div>
            </div>
            <div class="form-group">
              <label class="mb-2 block font-medium tracking-wide text-gray-700">{{ $t('users.last_name_label') }}</label>
              <div class="flex items-center justify-start">
                <input
                  v-model.trim="$v.lastName.$model"
                  class="form-input mb-2 mr-2 inline-flex h-10 w-80 shrink-0 overflow-hidden rounded-lg border-gray-300 bg-white px-4 text-xs font-medium hover:drop-shadow-md"
                  :class="{ 'border-red': $v.lastName.$error }"
                />
                <div v-if="$v.lastName.required.$invalid" class="text-xs leading-normal text-gray-400">{{ $t('users.required') }}</div>
              </div>
              <div v-if="$v.lastName.minLength.$invalid" class="text-xs leading-normal text-danger">
                {{ $t('users.last_name_min_length_error_message') }} {{ $v.lastName.minLength.$params.min }} {{ $t('users.letters') }}.
              </div>
            </div>
            <div class="mt-10 flex items-center justify-end">
              <button
                class="rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:text-primary"
                @click="closeModal"
              >
                {{ $t('buttons.cancel') }}
              </button>
              <button
                class="flex items-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
                :class="{ 'cursor-not-allowed opacity-25': $v.$invalid }"
                :disabled="$v.$invalid"
                @click="handleCreateUser"
              >
                <div v-if="buttonNextIsLoading">{{ $t('users.loading_button') }}</div>
                <div v-else>{{ $t('users.simple_add_button') }}</div>
              </button>
            </div>
          </form>
        </div>
        <div v-else-if="step === 3">
          <div class="text-success">
            <hero-icon name="CheckCircleHeroIcon" classes="mx-auto w-20 h-20"></hero-icon>
          </div>
          <h3 class="my-4 text-center font-medium">{{ $t('users.confirmation_adding_user_message') }}</h3>
          <div class="mt-10 flex items-center justify-end">
            <button
              class="flex items-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
              @click="closeModalWithWindowRefresh"
            >
              {{ $t('users.ok_button') }}
            </button>
          </div>
        </div>
        <div v-else>
          <div class="text-yellow">
            <hero-icon name="WarningOutlineHeroIcon" classes="mx-auto w-20 h-20"></hero-icon>
          </div>
          <h3 class="my-4 text-center font-medium">{{ $t('users.error_adding_user_message') }}</h3>
          <div class="mt-10 flex items-center justify-end">
            <button
              class="flex items-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
              @click="closeModal"
            >
              {{ $t('users.ok_button') }}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps({
  clinics: {
    type: Array,
    required: true,
  },
  currentClinic: {
    type: Object,
    required: true,
  },
  roles: {
    type: Array,
    required: true,
  },
});

const showAddUserModal = ref(false);
const buttonNextIsLoading = ref(false);
const buttonAddIsLoading = ref(false);
const showError1 = ref(false);
const showError2 = ref(false);
const showClinicsDropdown = ref(true);
const step = ref(1);
const errorMessage1 = ref('');
const errorMessage2 = ref('');
const firstName = ref('');
const lastName = ref('');
const userEmail = ref('');
const selectedClinics = ref([]);
const selectedRole = ref('');

const validArray = item => item.length > 0;

const rules = computed(() => {
  if (step.value === 1) {
    return {
      selectedClinics: {
        validArray,
      },
      userEmail: {
        required,
        email,
      },
      selectedRole: {
        required,
      },
    };
  } else {
    return {
      firstName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
    };
  }
});

const $v = useVuelidate(rules, { firstName, lastName, userEmail, selectedClinics, selectedRole });

onMounted(() => {
  if (props.clinics.length <= 1) {
    showClinicsDropdown.value = false;
    selectedClinics.value = props.clinics.map(clinic => clinic.id);
  } else {
    selectedClinics.value = [props.currentClinic.id];
  }
  initErrorMessages();
});

watch(showAddUserModal, () => {
  if (showAddUserModal.value) {
    document.body.classList.add('overflow-hidden');
  } else {
    document.body.classList.remove('overflow-hidden');
  }
});

const closeModal = () => {
  showAddUserModal.value = false;
  showError1.value = false;
  showError2.value = false;
  buttonNextIsLoading.value = false;
  step.value = 1;
};

const closeModalWithWindowRefresh = () => {
  window.location.reload();
};

const handleCloseAlert1 = () => {
  showError1.value = false;
  initErrorMessages();
};

const handleCloseAlert2 = () => {
  showError2.value = false;
  initErrorMessages();
};

const initErrorMessages = () => {
  errorMessage1.value = t('users.standard_user_adding_message');
  errorMessage2.value = t('users.standard_user_adding_message');
};

const handleAddUser = async () => {
  try {
    if ($v.$invalid) {
      return;
    }
    buttonNextIsLoading.value = true;
    const res = await axios.post('/administrator/add_user_to_clinics', {
      user: {
        email: userEmail.value,
        role: selectedRole.value,
        clinics: selectedClinics.value,
      },
    });
    if (res.data.user === 'not_found') {
      step.value = 2;
    } else if (res.data.message) {
      throw new Error(res.data.message);
    } else {
      step.value = 3;
    }
    buttonNextIsLoading.value = false;
  } catch (error) {
    console.log(error.message);
    errorMessage1.value = error.message;
    showError1.value = true;
    buttonNextIsLoading.value = false;
  }
};

const handleCreateUser = async () => {
  try {
    if ($v.$invalid) {
      return;
    }
    buttonAddIsLoading.value = true;
    // axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const res = await axios.post('/administrator/create_user_and_add_to_clinics', {
      user: {
        email: userEmail.value,
        role: selectedRole.value,
        clinics: selectedClinics.value,
        first_name: firstName.value,
        last_name: lastName.value,
      },
    });
    if (res.data.message) {
      throw new Error(res.data.message);
    } else {
      step.value = 3;
      buttonAddIsLoading.value = false;
    }
  } catch (error) {
    console.log(error.message);
    step.value = 4;
    errorMessage2.value = error.message;
    showError2.value = true;
    buttonAddIsLoading.value = false;
  }
};
</script>
<style setup>
:root {
  --vs-border-radius: 8px;
  --vs-border-color: #d1d5db;
  --vs-selected-bg: #e1effe;
  --vs-selected-border-color: #e1effe;
  --vs-selected-color: #4691f7;
  --vs-controls-color: #4691f7;
}
</style>
