<template>
  <button id="toggle-fs" type="button" @click="toggle">
    <hero-icon name="ZoomOutHeroIcon" size="default"></hero-icon>
  </button>
</template>
<script>
export default {
  data() {
    return {
      fullscreen: false,
    };
  },
  methods: {
    toggle() {
      this.fullscreen === false ? this.openFullscreen() : this.closeFullscreen();
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.mozExitFullscreen) {
        /* Firefox */
        document.mozExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
      this.fullscreen = false;
    },
    openFullscreen() {
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.mozRequestFullscreen) {
        /* Firefox */
        elem.mozRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
      this.fullscreen = true;
    },
  },
};
</script>
<style lang="css" scoped>
#toggle-fs {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 2;
}
</style>
