<template>
  <div class="h-full" :style="{ maxHeight: `${maxDivHeight}px !important` }">
    <div ref="emailSubject" class="mb-4 flex items-center gap-4 text-gray-500"></div>
    <div class="py-4">
      <div v-show="loading" class="flex h-52 w-full justify-center">
        <TurningLoader message="" size="lg" text-color="text-textLight" />
      </div>
      <iframe id="email-preview-frame" ref="emailPreviewFrame" width="100%" :height="iframeHeight" frameborder="0" scrolling="no"></iframe>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { getEmailTemplateBody } from '@services/emailService';

const props = defineProps({
  clinicId: {
    type: Number,
    required: true,
  },
  emailType: {
    type: String,
    required: true,
  },
});

const emailSubject = ref(null);
const loading = ref(null);
const htmlPreview = ref({});
const iframeHeight = ref('500px'); // Initial height

// Calculate maximum height for the first div dynamically
const maxDivHeight = computed(() => window.innerHeight - 280);

onMounted(async () => {
  loading.value = true;
  htmlPreview.value = await getEmailTemplateBody({ clinicId: props.clinicId, emailType: props.emailType });
  loading.value = false;
  if (htmlPreview.value.data.subject) {
    emailSubject.value.innerHTML = '<h4>Objet:</h4>' + htmlPreview.value.data.subject;
  }
  if (htmlPreview.value.data.email_html) {
    const emailPreviewFrame = document.getElementById('email-preview-frame');
    const frameDocument = emailPreviewFrame.contentDocument || emailPreviewFrame.contentWindow.document;
    frameDocument.open();
    frameDocument.write(htmlPreview.value.data.email_html);
    frameDocument.close();
    // Add event listener to prevent default behavior on specific links
    frameDocument.querySelectorAll('a[href="javascript:void(0);"]').forEach(link => {
      if (link.target === '_blank') {
        link.addEventListener('click', function (event) {
          event.preventDefault();
        });
      }
    });
    // Wait for the content to load before adjusting height
    setTimeout(() => {
      const contentHeight = frameDocument.documentElement.scrollHeight || frameDocument.body.scrollHeight;
      iframeHeight.value = `${contentHeight}px`; // Set the iframe height based on content height
    }, 500); // Use a short delay to ensure content is rendered
  }
});
</script>
