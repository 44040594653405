<template>
  <div>
    <div id="flash" class="absolute z-50">
      <div class="fixed right-4 top-20 mt-2 grid grid-cols-1 gap-4">
        <SimpleToast v-show="displaySuccess" index="0" model="success" :message="successMessage" @close="handleCloseToast"></SimpleToast>
      </div>
    </div>
    <div v-if="isAnonymised" class="mb-6 flex w-full items-center">
      <hero-icon name="WarningHeroIcon" classes="text-primary"></hero-icon>
      <span class="ml-2 text-xs text-primary placeholder:text-textSecondary">{{ $t('patients.edit.rgpd_warning') }}</span>
    </div>
    <form class="relative px-12 sm:px-24 md:px-12 lg:px-24" name="patient">
      <p v-if="displayError" class="absolute -top-6 mb-4 pl-2 text-red">{{ error }}</p>
      <div class="mb-6 flex flex-wrap gap-6">
        <div class="flex w-80 grow flex-col items-start justify-start">
          <p class="mb-2 font-medium text-textSecondary">Civilité</p>
          <select
            v-model="prefix"
            name="patient[prefix]"
            class="focus:shadow-focus form-select h-10 w-full overflow-hidden rounded-lg border border-gray-200 bg-white px-4 text-xs font-medium placeholder:text-textSecondary hover:drop-shadow-md md:col-span-3 lg:col-span-2"
            :class="civilityTextColorClass"
          >
            <option value="" disabled selected>{{ $t('patients.edit.civility.placeholder') }}</option>
            <option v-for="(civ, index) in civilities" :key="'civility_' + index" :value="civ">{{ civ }}</option>
          </select>
        </div>
        <div class="flex w-80 grow flex-col items-start justify-start">
          <p class="mb-2 font-medium text-textSecondary">{{ $t('patients.edit.first_name.placeholder') }}</p>
          <input
            v-model="firstName"
            class="focus:shadow-focus h-10 w-full overflow-hidden rounded-lg border border-gray-200 bg-white px-4 text-xs font-medium text-textPrimary placeholder:text-textSecondary hover:drop-shadow-md"
            name="patient[first_name]"
          />
        </div>
        <div class="flex w-80 grow flex-col items-start justify-start">
          <p class="mb-2 font-medium text-textSecondary">{{ $t('patients.edit.last_name.placeholder') }}</p>
          <input
            v-model="lastName"
            class="focus:shadow-focus h-10 w-full overflow-hidden rounded-lg border border-gray-200 bg-white px-4 text-xs font-medium text-textPrimary placeholder:text-textSecondary hover:drop-shadow-md"
            name="patient[last_name]"
          />
        </div>
        <div class="flex w-80 grow flex-col items-start justify-start">
          <p class="mb-2 font-medium text-textSecondary">{{ $t('patients.edit.email.placeholder') }}</p>
          <input
            v-model="email"
            class="focus:shadow-focus h-10 w-full overflow-hidden rounded-lg border border-gray-200 bg-white px-4 text-xs font-medium text-textPrimary placeholder:text-textSecondary hover:drop-shadow-md"
            name="patient[email]"
          />
        </div>
        <div class="flex w-80 grow flex-col items-start justify-start">
          <p class="mb-2 font-medium text-textSecondary">{{ $t('patients.edit.phone_number.placeholder') }}</p>
          <vue-tel-input
            v-model="phoneNumber"
            :input-options="vueTelInputOptions"
            :disabled="isAnonymised"
            style-classes="w-full z-10 ring-blue-300 focus:shadow-focus h-10 rounded-lg border border-gray-200 bg-white text-xs text-textPrimary placeholder-gray-400 font-medium hover:drop-shadow-md"
          ></vue-tel-input>
        </div>
        <div class="flex w-80 grow flex-col items-start justify-start">
          <p class="mb-2 font-medium text-textSecondary">{{ $t('patients.edit.birth_date.placeholder') }}</p>
          <vue-tailwind-datepicker
            id="birthDatepicker"
            v-model="birthDate"
            as-single
            i18n="fr"
            name="patient[date_of_birth]"
            :formatter="formatter"
            placeholder="jj-mm-aaaa"
            input-classes="h-10 rounded-lg w-80 grow border border-gray-200 bg-white text-xs font-medium text-textPrimary placeholder-gray-400 hover:drop-shadow-md"
          />
        </div>

        <div class="flex w-80 flex-col items-start justify-start">
          <p class="mb-2 font-medium text-textSecondary">{{ $t('patients.edit.exit_date.placeholder') }}</p>
          <vue-tailwind-datepicker
            id="exitDatepicker"
            v-model="exitDate"
            disabled
            as-single
            i18n="fr"
            name="patient[exit_date]"
            :formatter="formatter"
            placeholder="jj-mm-aaaa"
            input-classes="focus:shadow-focus h-10 rounded-lg w-80 grow border border-gray-200 bg-white text-xs font-medium text-textPrimary placeholder-gray-400 hover:drop-shadow-md"
          />
        </div>
      </div>
      <div class="flex w-full items-center space-x-3 text-center md:text-left">
        <button
          type="button"
          class="mb-2 h-10 overflow-hidden rounded-lg border-none bg-primary px-6 text-center text-sm font-medium text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-gray-light disabled:text-textLight"
          :disabled="isAnonymised || loading"
          @click="handleSubmitForm"
        >
          <svg
            v-if="loading"
            aria-hidden="true"
            role="status"
            class="me-3 inline h-4 w-4 animate-spin text-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="#E5E7EB"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentColor"
            />
          </svg>
          {{ $t('patients.edit.submit_button') }}
        </button>
        <button
          v-if="showBoostLastReviewButton"
          type="button"
          class="mb-2 h-10 overflow-hidden rounded-lg border-none bg-blue-light px-6 text-center text-sm font-medium text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-gray-light disabled:text-textLight"
          :disabled="disableResolicitation || loading || boostLoading"
          @click="handleBoost"
        >
          <TurningLoader v-if="boostLoading" size="xs" class="mr-2" />
          {{ $t('patients.edit.boost_button') }}
        </button>
      </div>
    </form>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import VueTailwindDatepicker from 'vue-tailwind-datepicker';
import dayjs from 'dayjs';
import axios from 'axios';
import { boostReview } from '@services/reviewService';
import SimpleToast from '../toasts/SimpleToast.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
  patient: {
    type: Object,
    required: true,
  },
  civilities: {
    type: Array,
    required: false,
    default: () => {
      return ['M.', 'Mme'];
    },
  },
  isAnonymised: {
    type: Boolean,
    default: false,
    required: false,
  },
  lastReviewId: {
    type: Number,
    default: null,
  },
  lastReviewBoostable: {
    type: Boolean,
    default: false,
  },
  showBoostLastReviewButton: {
    type: Boolean,
    default: false,
  },
});

const prefix = ref('');
const firstName = ref('');
const lastName = ref('');
const email = ref('');
const phoneNumber = ref('');
const birthDate = ref('');
const entryDate = ref('');
const exitDate = ref('');
const error = ref('');
const displayError = ref(false);
const loading = ref(false);
const boostLoading = ref(false);
const successMessage = ref('');
const displaySuccess = ref(false);
const disableResolicitation = ref(!props.lastReviewBoostable);
const formatter = ref({
  date: 'DD-MM-YYYY',
  month: 'MMM',
});
const vueTelInputOptions = ref({
  styleClasses:
    'bg-transparent rounded-r-lg text-textPrimary placeholder-gray-400 focus:rounded-r-lg target:rounded-r-lg visited:rounded-r-lg hover:rounded-r-lg active:rounded-r-lg autofill:rounded-r-lg',
  name: 'patient[phone_number]',
});

const civilityTextColorClass = computed(() => {
  return prefix.value === '' ? 'text-textSecondary' : 'text-textPrimary';
});

onMounted(() => {
  prefix.value = props.patient.prefix || '';
  firstName.value = props.patient.first_name;
  lastName.value = props.patient.last_name;
  email.value = props.patient.email;
  phoneNumber.value = props.patient.phone_number || '';
  birthDate.value = [null, undefined, ''].includes(props.patient.date_of_birth) ? '' : formatDateForDatepicker(props.patient.date_of_birth);
  entryDate.value = [null, undefined, ''].includes(props.patient.entry_date) ? '' : formatDateForDatepicker(props.patient.entry_date);
  exitDate.value = [null, undefined, ''].includes(props.patient.exit_date) ? '' : formatDateForDatepicker(props.patient.exit_date);
});

const formatDateForDatepicker = inputDate => {
  const formattedDate = dayjs(inputDate).format('DD-MM-YYYY');
  return formattedDate;
};

const handleCloseToast = () => {
  displaySuccess.value = false;
  successMessage.value = '';
};

const handleSubmitForm = async () => {
  const data = {
    patient: {
      prefix: prefix.value,
      first_name: firstName.value,
      last_name: lastName.value,
      email: email.value,
      phone_number: phoneNumber.value,
      date_of_birth: birthDate.value,
      entry_date: entryDate.value,
      exit_date: exitDate.value,
    },
  };
  loading.value = true;
  const url = `/patients/${props.patient.id}`;
  const response = await axios.put(url, data, { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } });
  loading.value = false;
  const responseData = response.data;
  if (responseData.success === true) {
    loading.value = false;
    displaySuccess.value = true;
    successMessage.value = responseData.message;
    setTimeout(() => {
      displaySuccess.value = false;
      successMessage.value = '';
    }, 3000);
  } else {
    loading.value = false;
    console.log(responseData);
    error.value = responseData.error;
    displayError.value = true;
    setTimeout(() => {
      displayError.value = false;
      error.value = '';
    }, 3000);
  }
};

const handleBoost = async () => {
  boostLoading.value = true;
  try {
    await boostReview({ reviewId: props.lastReviewId });
    boostLoading.value = false;
    disableResolicitation.value = true;
    displaySuccess.value = true;
    successMessage.value = t('patients.edit.success_boost');
    setTimeout(() => {
      displaySuccess.value = false;
      successMessage.value = '';
    }, 3000);
  } catch (error) {
    boostLoading.value = false;
    error.value = error.message;
    displayError.value = true;
    setTimeout(function () {
      displayError.value = false;
      error.value = '';
    }, 3000);
  }
};
</script>
<style scoped>
.vue-tel-input {
  border-radius: 0.5rem !important;
  display: flex;
  border: 1px solid #e5e7eb !important;
  text-align: left;
}
</style>
