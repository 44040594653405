<template>
  <div class="flex h-full flex-col justify-between">
    <div>
      <h2>{{ $t('import_patients.establishment_selection_form.establishments_question') }}</h2>
      <form class="mt-12 text-left" @submit.prevent>
        <div
          v-if="showError"
          class="mb-5 flex w-full items-center justify-between border-l-2 border-danger bg-red-100 p-4 text-sm text-danger"
          @click="handleCloseAlert"
        >
          <span class="mr-2 text-red-dark">{{ errorMessage }}</span>
          <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer" size="small"></hero-icon>
        </div>
        <fieldset>
          <div class="ml-4 sm:ml-6">
            <div class="mb-8 flex items-center">
              <input
                id="all"
                v-model="$v.selectedMode.$model"
                type="radio"
                name="mode"
                value="all"
                class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
              />
              <label for="all" class="ms-2 block text-sm font-medium text-gray-900 dark:text-gray-300">
                {{ $t('import_patients.establishment_selection_form.all_your_establishments') }}
              </label>
            </div>
            <div class="mb-8 flex items-center">
              <input
                id="selection"
                v-model="$v.selectedMode.$model"
                type="radio"
                name="mode"
                value="selection"
                class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
              />
              <label for="selection" class="ms-2 block text-sm font-medium text-gray-900 dark:text-gray-300">
                {{ $t('import_patients.establishment_selection_form.select_specific_establishments') }}
              </label>
            </div>
            <div v-if="showClinicsDropdown" class="form-group mb-8">
              <label v-if="clinics.length === 0" class="mb-2 block font-medium tracking-wide text-gray-700">{{
                $t('import_patients.establishment_selection_form.no_managed_establishment')
              }}</label>
              <div v-else>
                <div class="lg:mx-2y mx-8 flex items-center justify-start space-x-3 text-sm font-medium sm:mx-12 md:mx-16">
                  <v-select
                    v-model.trim="$v.selectedClinics.$model"
                    multiple
                    autocomplete
                    :close-on-select="false"
                    :deselect-from-dropdown="true"
                    :options="clinics"
                    :reduce="clinic => clinic['id']"
                    label="name"
                    class="w-full"
                    clearable
                    placeholder="Sélectionnez dans la liste..."
                  />
                  <div v-if="$v.selectedClinics.validArray.$invalid">
                    <p class="text-xs leading-normal text-danger">{{ $t('users.required') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="mt-40 flex items-center justify-center">
      <button
        class="mr-2 flex w-32 items-center justify-center rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:border hover:border-primary hover:text-primary hover:no-underline"
        @click="navigateToPage(beforePath)"
      >
        {{ $t('buttons.cancel') }}
      </button>
      <button
        class="flex w-32 items-center justify-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
        :disabled="$v.$invalid"
        @click="handleSubmit"
      >
        <div>{{ $t('buttons.next') }}</div>
        <hero-icon name="ChevronRightIonIcon" size="small"></hero-icon>
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { useImportPatientsStore } from '@stores/importPatients.js';
const store = useImportPatientsStore();

const { t } = useI18n();

const props = defineProps({
  clinics: {
    type: Array,
    required: true,
  },
  beforePath: {
    type: String,
    required: true,
  },
  currentClinic: {
    type: Object,
    required: false,
    default: () => {
      return {};
    },
  },
});
const emit = defineEmits(['next-step']);

const selectedMode = ref('all');
const errorMessage = ref('');
const selectedClinics = ref([]);
const showClinicsDropdown = ref(false);
const showError = ref(false);
const firstShowOfDropdown = ref(true);

onMounted(() => {
  showClinicsDropdown.value = false;
  selectedClinics.value = props.clinics.map(clinic => clinic.id);
  initErrorMessage();
});

const validArray = item => item.length > 0;

const rules = computed(() => {
  return {
    selectedClinics: {
      validArray,
    },
    selectedMode: {
      required,
    },
  };
});

const $v = useVuelidate(rules, { selectedClinics, selectedMode });

const initErrorMessage = () => {
  errorMessage.value = t('users.standard_user_adding_message');
};

const handleCloseAlert = () => {
  showError.value = false;
  initErrorMessage();
};

const handleSubmit = () => {
  storeSettings();
  emit('next-step');
};

const storeSettings = () => {
  store.updateEstablishments({
    establishments: selectedClinics.value,
  });
};

watch(selectedMode, () => {
  if (selectedMode.value === 'selection') {
    showClinicsDropdown.value = true;
    if (firstShowOfDropdown.value === true) {
      selectedClinics.value = props.currentClinic.id ? [props.currentClinic.id] : [];
      firstShowOfDropdown.value = false;
    }
  } else {
    showClinicsDropdown.value = false;
    selectedClinics.value = props.clinics.map(clinic => clinic.id);
  }
});

const navigateToPage = path => {
  window.location.href = path;
};
</script>
<style setup>
:root {
  --vs-border-radius: 8px;
  --vs-border-color: #d1d5db;
  --vs-selected-bg: #e1effe;
  --vs-selected-border-color: #e1effe;
  --vs-selected-color: #4691f7;
  --vs-controls-color: #4691f7;
  --vs-font-size: 14px;
  --vs-dropdown-z-index: 1000;
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 120px;
  --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);
}
</style>
