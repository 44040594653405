<template>
  <div>
    <div v-show="showError" class="flex w-full justify-end">
      <div class="absolute top-0 rounded-full bg-red-100 px-2 py-1 text-right text-xs">
        <span class="text-xs text-danger">{{ errorMessage }}</span>
      </div>
    </div>
    <div class="inline-flex w-full flex-wrap items-center gap-3">
      <label class="pointer-events-none flex flex-col">
        <span
          :class="[
            'pointer-events-none text-base font-medium',
            activable ? 'text-gray-dark dark:text-gray-light' : 'text-gray-400 dark:text-gray-500',
          ]"
          >{{ $t('automatic_google_review_response.activate') }}</span
        >
        <span
          :class="['font-small pointer-events-none text-xs', activable ? 'text-gray dark:text-gray-light' : 'text-gray-400 dark:text-gray-500']"
          >{{ $t('automatic_google_review_response.activate_int') }}</span
        >
      </label>
      <TurningLoader v-if="loading" size="sm" class="mr-2" />
      <input
        v-if="!loading"
        type="checkbox"
        :checked="activated"
        class="peer sr-only cursor-pointer"
        :disabled="!activable"
        @change="handleCheckboxChange"
      />
      <div
        :class="[
          'peer relative h-6 w-16 rounded-full after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:bg-white after:transition-all after:content-[\'\'] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 rtl:peer-checked:after:-translate-x-full sm:w-20 md:w-11',
          activable
            ? 'cursor-pointer bg-gray-200 after:border-gray-300 peer-checked:bg-blue-600 dark:border-gray-600 dark:bg-gray-700'
            : 'dark:border-gray-default cursor-not-allowed bg-gray after:border-gray-light peer-checked:bg-blue-dark dark:bg-gray-mediumdark',
        ]"
        @click="toggleCheckbox"
      ></div>
      <div>
        <div data-tooltip-target="activate-automatic-response-tooltip" data-tooltip-placement="bottom" class="h-5 w-5">
          <hero-icon name="InformationOutlineIonIcon" classes="ml-2 text-info hover:text-primary" size="large"></hero-icon>
        </div>
        <DefaultTooltip
          id="activate-automatic-response-tooltip"
          :message="$t('automatic_google_review_response.explanation')"
          classes="w-2/3 bg-primary text-white"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';
import { activateAutomaticGoogleReviewResponseFeature, deactivateAutomaticGoogleReviewResponseFeature } from '@services/reviewService';

const props = defineProps({
  clinicId: { type: Number, required: true },
  activable: Boolean,
  activated: Boolean,
});

const activated = ref(props.activated);
const errorMessage = ref('');
const showError = ref(false);
const loading = ref(false);

const handleCheckboxChange = async event => {
  loading.value = true;
  if (event.target.checked) {
    try {
      await activateAutomaticGoogleReviewResponseFeature({ clinicId: props.clinicId });
      setTimeout(function () {
        activated.value = true;
        loading.value = false;
        window.location.reload();
      }, 1500);
    } catch (error) {
      loading.value = false;
      errorMessage.value = error.message;
      showError.value = true;
      setTimeout(() => (showError.value = false), 4000);
    }
  } else {
    try {
      await deactivateAutomaticGoogleReviewResponseFeature({ clinicId: props.clinicId });
      setTimeout(function () {
        activated.value = false;
        loading.value = false;
        window.location.reload();
      }, 1500);
    } catch (error) {
      loading.value = false;
      errorMessage.value = error.message;
      setTimeout(function () {
        errorMessage.value = null;
      }, 1500);
    }
  }
};

const toggleCheckbox = () => {
  if (props.activable) {
    activated.value = !activated.value;
    handleCheckboxChange({ target: { checked: activated.value } });
  }
};
</script>
