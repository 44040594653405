<template>
  <div v-if="showCard" class="h-full w-full overflow-hidden rounded-xl drop-shadow-sm" :class="bgColor">
    <div
      v-if="googleLocation.attachedToClinic"
      :data-tooltip-target="tooltipId1"
      data-tooltip-placement="bottom"
      :class="`left-0 flex h-10 w-full items-center justify-center rounded-t-xl ${googleLocation.fetchReviewsSuccessfully ? 'bg-green' : 'bg-red-400'} p-3 font-semibold uppercase text-white`"
    >
      {{ googleLocation.fetchReviewsSuccessfully ? $t('google_locations.card.connected') : $t('google_locations.card.connection_error') }}
      <hero-icon v-if="!googleLocation.fetchReviewsSuccessfully" name="InformationIonIcon" classes="ml-2 w-5 text-white"></hero-icon>
    </div>
    <div v-else class="left-0 flex h-10 w-full items-center justify-center rounded-t-xl bg-purple-light p-3 font-semibold uppercase text-white">
      {{ $t('google_locations.card.to_boost') }}
    </div>
    <DefaultTooltip
      v-show="!googleLocation.fetchReviewsSuccessfully"
      :id="tooltipId1"
      classes="w-60 bg-red-400 text-white text-center"
      :message="$t('google_locations.card.no_more_connected_error', { error: googleLocation.lastFetchError || '.' })"
    />
    <div
      :class="`flex h-52 w-full items-start justify-end bg-cover ${googleLocation.photoUrl ? 'bg-center' : 'bg-gray-medium bg-top'}`"
      :style="{ 'background-image': `url(${displayedPictureUrl})` }"
    ></div>
    <div class="card-body">
      <div class="card-header p-8 py-6">
        <h2 class="mb-2 line-clamp-2 min-h-[56px] text-textSubTitle">{{ googleLocation.locationName }}</h2>
        <div class="flex items-center justify-start gap-2">
          <p v-if="googleLocation.averageRating" class="text-base font-semibold" :class="ratingColorClass">{{ googleLocation.averageRating }}</p>
          <div class="flex h-full flex-wrap items-center">
            <hero-icon v-for="nb in coloredStarsNb" :key="`coloredStar-${nb}`" name="StarHeroIcon" classes="w-4 text-warning"></hero-icon>
            <hero-icon v-for="nb in uncoloredStarsNb" :key="`uncoloredStar-${nb}`" name="StarHeroIcon" classes="w-4 text-gray-medium"></hero-icon>
          </div>
          <p v-if="googleLocation.totalReviewCount" class="text-sm text-textLight">({{ googleLocation.totalReviewCount }})</p>
        </div>
      </div>
      <div class="border border-gray-light"></div>
      <div class="h-40 px-8 pt-6">
        <div v-if="googleLocation.address" class="mb-2 flex items-center gap-2">
          <div class="h-5 w-5">
            <hero-icon name="MapPinHeroIcon" classes="w-5 h-5 text-gray"></hero-icon>
          </div>
          <span class="text-textSubtitle text-sm font-medium md:text-xs">{{ googleLocation.address }}</span>
        </div>
        <div v-if="googleLocation.primaryPhone" class="mb-2 flex items-center gap-2">
          <div class="h-5 w-5">
            <hero-icon name="PhoneHeroIcon" classes="w-5 h-5 text-gray"></hero-icon>
          </div>
          <span class="text-textSubtitle text-sm font-medium md:text-xs">{{ googleLocation.primaryPhone }}</span>
        </div>
        <div v-if="googleLocation.websiteUrl" class="mb-2 flex items-center gap-2">
          <div class="h-5 w-5">
            <hero-icon name="GlobeHeroIcon" classes="w-5 h-5 text-gray"></hero-icon>
          </div>
          <a class="text-textSubtitle text-sm font-medium md:text-xs" :href="googleLocation.websiteUrl" target="_blank">{{ websiteDomain }}</a>
        </div>
        <div v-if="googleLocation.mapsUrl" class="mb-2 flex items-center gap-2">
          <div class="h-5 w-5">
            <hero-icon name="MapHeroIcon" classes="w-5 h-5 text-gray"></hero-icon>
          </div>
          <a class="text-textSubtitle text-sm font-medium md:text-xs" :href="googleLocation.mapsUrl" target="_blank">{{
            $t('google_locations.card.google_maps')
          }}</a>
        </div>
      </div>
      <div v-if="googleLocation.attachedToClinic" class="w-full px-8 pb-6 pt-3">
        <div class="mb-2 flex w-full justify-center">
          <div v-show="googleLocation.attachedToClinic" class="w-full rounded-md bg-blue-100 px-4 py-2">
            <div class="flex items-center">
              <p class="text-sm font-medium text-primary md:text-xs">{{ $t('google_locations.card.google_account') }}</p>
              <form v-if="!googleLocation.fetchReviewsSuccessfully" :action="googleConnectPath" method="post">
                <input type="hidden" name="authenticity_token" :value="csrfToken" />
                <button
                  type="submit"
                  data-turbo="false"
                  class="ml-2 rounded-lg bg-primary px-2 py-1 text-xs font-medium text-white transition-all duration-100 ease-out hover:scale-110 hover:no-underline hover:drop-shadow-md group-hover:drop-shadow-lg"
                >
                  {{ $t('google_locations.card.reconnect') }}
                </button>
              </form>
            </div>
            <div class="flex items-center justify-start gap-2">
              <div class="h4 w-4">
                <hero-icon name="MailOutlineHeroIcon" classes="w-4 text-primary"></hero-icon>
              </div>
              <span class="truncate text-sm font-medium text-primary md:text-xs">{{ googleLocation.googleAccountEmail }}</span>
            </div>
            <div v-if="googleLocation.googleAccountConnectedAt" class="flex items-center justify-start gap-2">
              <div class="h4 w-4">
                <hero-icon name="CalendarIonIcon" classes="w-4 h-4 text-primary"></hero-icon>
              </div>
              <span class="text-left text-sm font-medium text-primary md:text-xs">{{
                $t('google_locations.card.connected_at', { connectedAt: googleLocation.googleAccountConnectedAt })
              }}</span>
            </div>
            <div class="mt-1 flex items-center justify-start">
              <p class="text-left text-sm font-medium text-primary md:text-xs">
                {{
                  googleLocation.lastFetchSuccessAt
                    ? $t('google_locations.card.last_synced_at', { lastFetch: googleLocation.lastFetchSuccessAt })
                    : $t('google_locations.card.no_last_synced_at')
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="px-8 pb-6 pt-3">
        <div class="flex flex-col items-center justify-center gap-2">
          <div v-if="boostButtonClicked" class="flex items-center justify-center rounded-full px-6 py-2">
            <hero-icon name="RocketHeroIcon" classes="mr-1 h-4" :class="`text-${boostMessageColor}`"></hero-icon>
            <div class="text-center text-sm font-semibold" :class="`text-${boostMessageColor}`">{{ boostMessage }}</div>
          </div>
          <button
            v-else
            class="flex items-center justify-center rounded-full bg-purple px-6 py-2 text-sm text-white transition-all duration-100 ease-out hover:scale-110 hover:bg-success hover:drop-shadow-md"
            @click="handleClickBoostButton"
          >
            <hero-icon name="RocketHeroIcon" classes="mr-1" size="small"></hero-icon>
            <div class="text-left text-sm font-semibold">{{ $t('google_locations.card.boost_page') }}</div>
          </button>
          <div v-if="ignoreButtonClicked" class="flex items-center justify-center rounded-full px-2 py-1">
            <div class="text-center text-sm font-semibold text-red">{{ ignoreMessage }}</div>
          </div>
          <button
            v-else
            class="flex cursor-pointer items-center justify-center rounded-full border border-transparent bg-transparent px-2 py-1 text-xs text-gray-400 hover:border-red hover:text-red"
            @click="handleClickIgnoreButton"
          >
            <hero-icon name="EyeSlashOutlineHeroIcon" classes="mr-1" size="small"></hero-icon>
            <div class="text-left text-sm font-semibold">{{ $t('google_locations.card.ignore_page') }}</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { v4 as uuidv4 } from 'uuid';
import { postBoostGoogleLocation, postIgnoreGoogleLocation } from '@services/googleLocationService.js';
import officeLocationImageUrl from '@images/office_location.png';
// import _ from 'lodash';

const { t } = useI18n();

const props = defineProps({
  googleLocation: {
    type: Object,
    required: true,
  },
  bgColor: {
    type: String,
    required: false,
    default: 'bg-white',
  },
  googleConnectPath: {
    type: String,
    required: false,
    default: '/',
  },
});

// const emits = defineEmits(['close']);

const boostButtonClicked = ref(false);
const ignoreButtonClicked = ref(false);
const boostMessage = ref(t('google_locations.card.page_boosted'));
const boostMessageColor = ref('purple');
const ignoreMessage = ref(t('alerts.standard_error_message'));
const showCard = ref(true);
const csrfToken = ref(null);
const tooltipId1 = ref(uuidv4());

onMounted(() => {
  boostButtonClicked.value = !!props.googleLocation.boostedAt;
  csrfToken.value = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
});

const handleClickBoostButton = async () => {
  const response = await postBoostGoogleLocation(props.googleLocation.id);
  if (response.status != 200) {
    boostMessageColor.value = 'red';
    boostMessage.value = (response.data && response.data.message) || t('alerts.standard_error_message');
    boostButtonClicked.value = true;
    setTimeout(() => {
      boostButtonClicked.value = false;
      boostMessageColor.value = 'purple';
      boostMessage.value = t('google_locations.card.page_boosted');
    }, 2000);
  } else {
    const successMessage = response.data && response.data.message;
    if (successMessage) {
      boostMessage.value = successMessage;
      boostMessageColor.value = 'green';
      boostButtonClicked.value = true;
      setTimeout(() => {
        boostMessageColor.value = 'purple';
        boostMessage.value = t('google_locations.card.page_boosted');
      }, 3000);
    } else {
      boostButtonClicked.value = true;
    }
  }
};

const handleClickIgnoreButton = async () => {
  const response = await postIgnoreGoogleLocation(props.googleLocation.id);
  if (response.status != 200) {
    ignoreMessage.value = (response.data && response.data.message) || t('alerts.standard_error_message');
    ignoreButtonClicked.value = true;
    setTimeout(() => {
      ignoreButtonClicked.value = false;
      ignoreMessage.value = t('alerts.standard_error_message');
    }, 2000);
  } else {
    ignoreButtonClicked.value = false;
    showCard.value = false;
  }
};

const displayedPictureUrl = computed(() => {
  return props.googleLocation.photoUrl || officeLocationImageUrl;
});

const coloredStarsNb = computed(() => {
  return Math.round(props.googleLocation.averageRating);
});

const uncoloredStarsNb = computed(() => {
  return props.googleLocation.maxRating - Math.round(props.googleLocation.averageRating);
});

const ratingColorClass = computed(() => {
  const rating = props.googleLocation.averageRating;
  let colorClass = 'text-textLight';
  if (rating < 4) {
    colorClass = 'text-red';
  } else if (rating < 4.5) {
    colorClass = 'text-yellow';
  } else {
    colorClass = 'text-green';
  }
  return colorClass;
});

const websiteDomain = computed(() => {
  // Create a new URL object with the provided URL
  const parsedUrl = new URL(props.googleLocation.websiteUrl);
  // Get the hostname (domain) from the parsed URL
  const hostname = parsedUrl.hostname;
  // Split the hostname by '.' to separate the parts
  const parts = hostname.split('.');
  // Get the last two parts, which represent the domain name and extension
  const domainName = parts[parts.length - 2];
  const domainExtension = parts[parts.length - 1];
  // Return an object containing the domain name and extension
  return `${domainName}.${domainExtension}`;
});
</script>
